import React, { Component } from 'react';
import { connect } from 'react-redux';

var thePlanRootStyle = {
    color: '#eee',
    minHeight: '100vh',
    margin: '15px',
    fontSize: "x-large"
}

class ThePlan extends Component {

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render() {
        return (
            <div style={thePlanRootStyle} >
                <div hidden>
                    <h1>Welcome to the section all about my plan for thru-hiking the PCT.</h1>
                </div>


                <p>Ah, yes, The Plan.&nbsp;</p>
                <p><br /></p>
                <p>Well, as previously stated - plans are useless, but planning is essential. &nbsp;So &quot;The Plan&quot;, as it were, is a rough outline of the balancing act between family, weather and approximately 2,800 miles of trail. &nbsp;So, without further ado, here is the high level plan:</p>
                <ul>
                    <li>Start early in the thru-hiking season.</li>
                    <li>Hike to southern Sierras and then drive home.</li>
                    <li>Stay with family until Sierras become snow-free.</li>
                    <li>Resume the thru-hike.</li>
                    <li>Hike until Snoqualmie Pass, which is 45 minutes from my house.</li>
                    <li>Flip to the Northern Terminus and then hike south-bound to Snoqualmie Pass.</li>
                </ul>
                <p><br /></p>
                <p>Ok, why this plan? &nbsp;Well, first, I&apos;ve got to reconnect with the family throughout the summer, so getting a multi-week connection in mid-to-late-May is definitely an opportunity.
                    Also, by finishing at Snoqualmie Pass I will finish right near my house, which makes it easy for my family and friends to be there for the finish.
                    </p>
                <p><br /></p>
                <p>There are <strong>alot</strong> of really good resources for planning a thru-hike.  From Guthook app, to postholer.  Here are a few of the resources I used to derive an early outline of
                    my hike, but also refine it as recent data about snow levels and the like become available.</p>
                <ul>
                    <li><a href="https://atlasguides.com/trail-guides">Guthooks trail guide</a></li>
                    <li><a href="https://www.postholer.com/planner/hikePlanner.php">Postholer trail planner</a></li>
                    <li><a href="https://www.postholer.com/snow/Pacific-Crest-Trail/1">Postholer snow conditions report</a></li>
                </ul>
                <p><br /></p>
                <img alt="Rough Plan 1"  width="1000"
                    src={process.env.PUBLIC_URL + '/images/PlanNOBO.JPG'} />
                <img alt="Rough Plan 2" width="1000"
                    src={process.env.PUBLIC_URL + '/images/PlanSOBO.JPG'} />
                <p><br /></p>
                <p>Now with that understanding of a rough outline, the next layer of planning is food planning.  So, first, let's talk calories!</p>
                <video ref="backpackingFoodRef"
                    width="560"
                    height="314"
                    margin="15px"
                    controls
                    preload="auto"
                >
                    <source src="https://seanandthepctstorage.blob.core.windows.net/nonblogmedia/BackpackingFood.mp4" type="video/mp4" />
                </video>
                
                <p>Now that we have that out of the way, it is on to resupplies and what not. &nbsp;In order to really do this I kind of did some serious geeking out. &nbsp;I
                downloaded the GPS files for the PCT and put them in Excel. &nbsp;From there I could calculate the average gradient of any section or mileage of the PCT. &nbsp;So I created Excel sheets for each Section
                of the PCT. &nbsp;Then I took the results into OneNote and created little cheat sheets of each section, adding in Guthooks information on water, food, camping, markers and such that I might find
                interesting or needful based on how I hike.  For example, I hike about 20 miles a day, so food and water supply calculations are based on that. Even camping spot data is somewhat filtered by this.  Water
                data is definitely filtered based on whether I personally judged a particular water spot useful based on comments in Guthooks, but also other information.  So, in short, it's a bunch of filtered data based on
                    what I think might be useful to have on paper.  On paper.  In case my phone and other sources of information die. &nbsp;I&apos;ve put all of this below, but first...</p>
                <p><br /></p>
                <p><strong>DISCLAIMER:&nbsp;</strong>I did all this information for my own use and explicitly note I left much information out because of personal preferences. &nbsp;Any information you choose to use is at your own risk and you accept that risk by downloading, copying, or doing anything with it. &nbsp;Period.</p>
                <p><br /></p>
                <p>Ok, with that caveat, here&apos;s a quick example of part of one of my cheat sheets.</p>
                <p><br /></p>
                <img alt="Cheat sheet"
                    src={process.env.PUBLIC_URL + '/images/SampleCheatSheet.png'} />
                <p><br /></p>
                <p>For the geeks out there, <a href={process.env.PUBLIC_URL + '/resources/PCT_Section_Elevations.xlsx'} download>here</a> is the full Excel sheet with all the GPS data, etc. &nbsp;Geek-out.</p>
                <p><br /></p>
                <p>My cheat sheets are <a href={process.env.PUBLIC_URL + '/resources/PCT Info.one'} download>here</a>.</p>
            </div>
        );
    }
}


export default connect()(ThePlan);