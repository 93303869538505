import React, { Component } from 'react';
import { connect } from 'react-redux';


var prePCTWorkRootStyle = {
    color: '#eee',
    minHeight: '100vh',
    margin: '15px',
    fontSize: "x-large"
}

class PrePCTWork extends Component {

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render() {
        return (

            <div style={prePCTWorkRootStyle}>
                <div hidden>
                    <h1>Welcome to the section about everything I did and learned prior to heading out on the PCT</h1>
                </div>

                <a href={process.env.PUBLIC_URL + '/images/Rcf3a811637eb324a047ecb0f83088eff.jpg'}>
                    <img src={process.env.PUBLIC_URL + '/images/Rcf3a811637eb324a047ecb0f83088eff.jpg'} style={{ float: "left", margin: "10px" }} />
                </a>


                <p>As I&apos;ve approached this idea of doing the PCT, I learned <strong>a lot</strong> over the past few years. &nbsp;But before I get too far into it all, let me tell you how I&apos;m going to organize all of this. &nbsp;First, I want to get some common definitions out of the way. &nbsp;You know, some people mean very different things when you say <em>ultralight</em>, for example. &nbsp;Then, there are a few things (like my age) that really influenced things like gear choices, so I&apos;ll go over a bunch of these subjective things. &nbsp;After all of that, I&apos;ll get into the various things I learned along the way.</p>
                <p><br /></p>
                <p>I talk <em>a lot</em> with my friends and fellow hikers about gear and such. &nbsp;It&apos;s a common passion we all have and we&apos;re always searching for that thing that is better than what we have. &nbsp;To avoid misunderstandings I&apos;ve used the following to help define exactly what I mean by different words:</p>
                <table>
                    <tbody>
                        <tr>
                            <td>
                                <div style={{ margin: "10px" }}>Super Ultralight backpacking</div>
                            </td>
                            <td>
                                <div style={{ margin: "10px" }}>Base weight of less than 5 lbs</div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div style={{ margin: "10px" }}>Ultralight backpacking</div>
                            </td>
                            <td>
                                <div style={{ margin: "10px" }}>Base weight between 5 and 10 lbs</div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div style={{ margin: "10px" }}>Lightweight backpacking</div>
                            </td>
                            <td>
                                <div style={{ margin: "10px" }}>Base weight between 10 and 20 lbs</div>
                            </td>
                        </tr>
                    </tbody>
                </table>

                <p><br /></p>
                <p>Now, I&apos;ve seen PCT thru-hikers in the Ultralight range, but really I don&apos;t see myself doing that. &nbsp;Why? &nbsp;Well, comfort mostly - but we will get into that after we talk about some of these defining influencers in my choices.</p>
                <p>1) <strong>Age</strong>. &nbsp;Yeah, I&apos;m in my mid-50s. &nbsp;I&apos;m not 20, 30 or even 40 anymore. &nbsp;My body is different and has different requirements. &nbsp;I&apos;ve done several PCT sections of 100+ miles and I&apos;ve learned that there&apos;s a formula I have to follow to be able to get up the next morning and be able to function that next day. &nbsp;Basically, for every hour of hiking I need one hour of sleep. &nbsp;Plus I need an hour at lunch, and several 15 minute breaks during the day. &nbsp;As such, my typical day is to wake up around 6am, take breaks every couple of hours when on the trail, usually when I&apos;m getting water anyway. &nbsp;Take that long lunch, with a little cat nap if possible, then stop by 6pm and asleep by 8 or 9pm. &nbsp;If I don&apos;t follow this schedule, then the next day I&apos;m running at a deficit and usually poop out early. &nbsp;When I was younger, well, the rules were different and more flexible.</p>
                <p>2) <strong>Family</strong>. &nbsp;Married with two young kids makes it more complicated to take to the trail for six months. &nbsp;As such, I&apos;ve had to plan with my wife and kids how to stay in touch while on the trail, how to support them while I&apos;m on the trail, and how they can support my thru-hike. &nbsp;Taking my family into account added some gear, but more impacted the planning phase (see &quot;The Plan&quot; section).</p>
                <p>3) <strong>Money.</strong>&nbsp; I&apos;m later in my working career and thus have more money than when I was younger. &nbsp;This means that I could buy-and-try more gear with less guilt. &nbsp;I can also throw money at logistical problems. &nbsp;For example, flip-flopping means a couple of airplane flights or one-way car rentals... no problem.</p>
                <p><br /></p>
                <p>Ok, with this background, let&apos;s dive into how I approached this whole PCT thing.</p>
                <p><br /></p>
                <p>At first I spent a lot of time and energy just reading blogs of thru-hikers, looking at maps and just wrapping my head around the enormity of 2,700+ miles that is the PCT. &nbsp;This was mental-adjustment time, creating a frame of reference from which all my gear, family and logistic thinking would flow. &nbsp;A weekend, or even a week, is very different that six months. &nbsp;Choices I make, yeah, well, they can change on the fly, but the closer I get to getting it right from the start, the greater the chance of completing the PCT. &nbsp;Or so I think. &nbsp;I follow the quote of Eisenhower, &quot;Plans are useless, but planning is essential.&quot; &nbsp;In short, the planning helps me figure out all the priorities and possibilities, but I shouldn&apos;t get married to a specific plan because something always goes drastically differently. &nbsp;Hence, be flexible, but knowledgable.</p>
                <p><br /></p>
                <p>The first thing I realized is that I just can&apos;t be gone from my family for six months. &nbsp;So, some combination of me going back to see them, or them coming to see me, needs to happen. &nbsp;More on this in &quot;The Plan&quot; section.</p>
                <p><br /></p>
                <p>Next up was that I needed to update my backpacking gear knowledge from its circa-1999 understanding. &nbsp;I spent a <em>ton</em> of time reading ultralight backpacking gear reviews, blogs and the <a href="//halfwayanywhere.com">halfwayanywhere.com</a> PCT gear info. &nbsp;I started putting together a list of things I thought I want/need on a thru-hike. &nbsp;I&apos;ll be honest, it included things like a lightweight chair and other things that would embarrass me now. &nbsp;All in all, my first list of things came to a base weight of 27 lbs. &nbsp;(which is really hard to admit, but at least I didn&apos;t actually buy it all - it was just a list)</p>
                <p><br /></p>
                <p>My Super Ultralight backpacking friend slashed thru my list like Indiana Jones with a machete thru a jungle. &nbsp;Suddenly my list was down to 8 lbs, but with a lot of choices I didn&apos;t feel comfortable with. &nbsp;So, I did some of the things (like cutting down my toothbrush, not carrying a second set of clothes), re-instated some other things (like carrying a GPS location device) and compromised on some things (changing out his tarp-plus-bivy with a single-wall tent). &nbsp;I was down to a 17 lb base weight!</p>
                <p><br /></p>
                <p>So, using money I basically just went out and bought my entire gear list. &nbsp;I planned a weekend backpacking trip as my first use of the new gear list. &nbsp;This was super valuable - I found that some things just didn&apos;t work for me, but there is no way I would have found out without taking it out on the trail. &nbsp;For example, my inflatable sleeping pad was so squeaky loud whenever I moved that it woke me up all night. &nbsp;Ugh.</p>
                <p><br /></p>

                <a href={process.env.PUBLIC_URL + '/images/PCT-overview-map-on-1-page-by-Magellan-v.1.2018.jpg'}>
                    <img src={process.env.PUBLIC_URL + '/images/PCT-overview-map-on-1-page-by-Magellan-v.1.2018.jpg'} style={{ float: "right", margin: "10px", maxWidth: "375px" }} />
                </a>


                <p>Well, then I made some changes, started cutting even more things out and got my base weight under 14 lbs. &nbsp;Logically the next step was to take a longer trip, so I hiked PCT WA
                Section J. &nbsp;OUCH! &nbsp;Great shoes for a weekend did not translate into comfort going up and down mountains for four days in a row. &nbsp;Toenails were bruised, every step
                hurt, my knees were killing me and I was totally wiped out. &nbsp;Another thing I had tried out was taking my electronics (phone and Garmin InReach Explorer+). &nbsp;The batteries
                should and did last for the section, but I found myself worrying all the time about how much percent of battery was left. &nbsp;It is a mental tax to worry, and I will need all
                my mental toughness when doing the full thru-hike, so I decided to carry a power bank. &nbsp;Yes, it is extra weight but the mental security is worth it. &nbsp;In the end, the
            additional shakedown of the gear was invaluable. &nbsp;Back to the drawing board for some items.</p>
                <p><br /></p>
                <p>Now I was beginning to figure out that due to my age I had to prioritize my recuperation time (sleeping) and ensure that my trail time (hiking) didn&apos;t over stress my system. &nbsp;I cut more weight, found different shoes, changed sleeping pad (again), and was ready to try again. &nbsp;I figured I was down to my &apos;bare minimum&apos; of what I could survive with on the PCT. &nbsp;But, was that enough to make it thru the less-ideal situations? &nbsp;I had to try it out. &nbsp;So... &nbsp;I went snow backpacking for one night. &nbsp;Hiked up thru 3&apos;-5&apos; of packed snow and spent an absolutely miserable night sleeping in the cold. &nbsp;The sleeping pad was too thin (the Z-lite), I was cold from the ground all night. &nbsp;So, again, back to the drawing board.</p>
                <p><br /></p>
                <p>This time I figured I knew the &apos;bare minimum&apos;, so only needed to add back the differences to make the less-than-ideal situations more sustainable, i.e. something I could do day-in and day-out. &nbsp;I swapped pads (yet again), chose a different single-wall tent (lighter weight), added back some comfort weight (a whole toothbrush, because whole the heck wants to brush with two fingers for six months!). &nbsp;I was dialed in at 13 lbs. &nbsp;</p>
                <p><br /></p>


                <p>That summer I did Washington Section I, 100+ miles. &nbsp;Feet were great, pack and sleep system were nice, overall, very happy camper with everything. &nbsp;Well, except for my knees. &nbsp;They still really hurt on all downhill parts and pain, pain, pain. &nbsp;I was lamenting with a thru-hiker and she said she had the same problem right from day 3 of her adventure. &nbsp;The compression straps were recommended and it took a couple of weeks for the pain to totally go away, but never a problem in over a thousand miles. &nbsp;So, I added that to my list of things to carry.</p>
                <p><br /></p>
                <p>Or so I thought. &nbsp;Then I did PCT CA Section A. &nbsp;My thinking was that I would try out my gear in the desert and gain confidence in hiking those 20+ mile stretches with no water. &nbsp;Turns out that the largest rain fall in 100+ years happened while I was on the trail. &nbsp;I was hiking the desert during a monsoon.</p>
                <p><br /></p>
                <p>Well, I learned that I needed better rain gear, I learned that a single-wall tent - though amazingly light, may not be the best choice for me (see &quot;From The Trail Blog&quot; entries for California Section A). &nbsp;I did find an amazingly comfortable backpack and my knees didn&apos;t hurt for a single minute. &nbsp;In the end, it was well worth it. &nbsp;I also decided to take the plunge into actually trying these modern double-wall tents and found that even though heavier, the trade-off better balances my age needs (i.e. I&apos;ll sleep and live more comfortably on the trail).</p>
                <p><br /></p>
                <p>Things I&apos;ve learned:</p>
                <ul>
                    <li>What works and what doesn&apos;t is a personal thing, based on some unchangeable things (like age) and some personal priority trade-offs.</li>
                    <li>What works and what doesn&apos;t can only be found out on the trail. &nbsp;Either ahead of time, by spending time doing shake-down hikes, or during your thru-hike. &nbsp;The during approach can lead to serious discomfort (like my feet and knees) and threaten the ability to complete the thru-hike.</li>
                    <li>One week trips will tell you more about your gear than weekend trips.</li>
                    <li>You&apos;ll know you are dialed in when you can look at a review, or suggested pack list, and know exactly why your pack is <em>not ever</em> going to be like that pack. &nbsp;Until then, keep futzing on the parts where there is doubt.</li>
                </ul>
                <p><br /></p>
                <p>Now, I've written down ever piece of gear I've ever bought and/or tried in the "Gear Reviews" section - I hope that you find this section and that section useful!</p>
            </div>
        );
    }
}

export default connect()(PrePCTWork);