import React, { Component } from 'react';
import { HashLink as Link } from 'react-router-hash-link';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { GoogleMap, withGoogleMap, withScriptjs, Marker } from "react-google-maps";
import ReactHtmlParser from 'react-html-parser';
import { getBlogEntryActionCreators } from '../store/GetBlogEntry';
import loading from './loading.gif';
//import ImageGallery from "react-image-gallery";
import ImageGallery from './image-gallery.js';
import "./image-gallery.css";

var rootBlogEntryStyle = {
    color: '#eee',
    minHeight: "770px",
    height: "100vh",
    margin: '15px',
    fontSize: "x-large"
}

var blogEntryLoadingStyle = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    color: '#eee',
    margin: '15px',
    fontSize: "x-large"
}


const ApiKey = "AIzaSyCkIz3Z6Tzu1BBovXA8Vxn-ziTCVPyFHVE";

const mediaPathPrefix = "https://seanandthepctstorage.blob.core.windows.net/blogphotos/";




const MyMapComponent = withScriptjs(withGoogleMap((props) => {

    console.log(props);

    var center = { lat: 47.60875605372471, lng: -122.09173964626805 };

    if (props.markerList.length > 0) {
        var selectedMarkerIndex = 0;

        for (var i = 0; i < props.markerList.length; i++) {
            if (props.selectedMarkerId === props.markerList[i].MarkerId) {
                selectedMarkerIndex = i;
                break;
            }
        }

        center.lat = props.markerList[selectedMarkerIndex].Latitude;
        center.lng = props.markerList[selectedMarkerIndex].Longitude;
    }

    return (
        <GoogleMap
            defaultZoom={15}
            center={center}
            defaultCenter={center}
        >
            {props.markerList.map((item) =>
                <Marker
                    key={item.MarkerId}
                    icon={props.selectedMarkerId === item.MarkerId ? { url: "https://maps.google.com/mapfiles/ms/icons/pink-dot.png" } : { url: "https://maps.google.com/mapfiles/ms/icons/purple-dot.png" }}
                    position={{ lat: item.Latitude, lng: item.Longitude }}
                    onClick={props.onMarkerClick.bind(this, { MarkerId: item.MarkerId, Context: props.onMarkerClickContext })}
                />
            )
            }
        </GoogleMap>
    );
}
));


class BlogEntry extends Component {


    constructor() {
        super();

        this.images = [];
    }

    state = {
        windowSize: window.innerWidth,
        mediaIndexDisplayed: 0
    };


    componentDidMount() {
        // This method runs when the component is first added to the page
        var id = parseInt(this.props.match.params.id, 10);
        this.props.getBlogEntryActionCreators.requestBlogEntry(id);

        this.imageGallery = React.createRef();

        window.addEventListener('resize', this.handleWindowSizeChange);

        window.scrollTo(0, 0);
    }


    componentWillUnmount() {
        window.removeEventListener('resize', this.handleWindowSizeChange);
    }

    handleWindowSizeChange = () => {
        this.setState({ windowSize: window.innerWidth });
    };


    renderWidthWarningIfNeeded() {
        if (this.state.windowSize < 700) {
            return (
                <div style={{ width:"100%", color: "#eee", fontSize: "large" }}>
                    <p> Better viewed in landscape mode :) </p>
                </div>
            );
        }
    }


    onMarkerClick(event) {
        var markerId = event.MarkerId;
        var context = event.Context;

        var id = parseInt(context.props.match.params.id, 10);

        var blogEntryList = context.props.getBlogEntry.blogEntryList;

        var blogEntry = null;
        var i;

        for (i = 0; i < blogEntryList.length; i++) {
            if (blogEntryList[i].Id === id) {
                blogEntry = blogEntryList[i];
                break;
            }
        }

        var selectedMediaIndex = 0;

        for (i = 0; i < blogEntry.MediaList.length; i++) {
            if (markerId === blogEntry.MediaList[i].MarkerId) {
                selectedMediaIndex = i;
                break;
            }
        }

        context.setState({ mediaIndexDisplayed: selectedMediaIndex });
        context.imageGallery.current.slideToIndex(selectedMediaIndex);
    }


    _renderVideo(item) {

        if (this.state.mediaIndexDisplayed < 0) {
            return (<div />);
        }

        if (!this.images[this.state.mediaIndexDisplayed].embedUrl) {
            return (<div />);
        }

        if (item.embedUrl.localeCompare(this.images[this.state.mediaIndexDisplayed].embedUrl) !== 0) {
            return (<div />);
        }

        return (
            <div style={{ margin: "15px" }}>
                {
                    <video
                        width="560"
                        height="315"
                        margin="15px"
                        controls
                        preload="auto"
                    >
                        <source src={item.embedUrl} type="video/mp4" />
                    </video>
                }
            </div>
        );
    }

    renderCarousel(mediaList) {

        if (mediaList.length < 1) {
            return (<p></p>);
        }

        if (this.images.length === 0) {
            var galleryList = [];

            for (var i = 0; i < mediaList.length; i++) {
                if (mediaList[i].IsImage) {
                    galleryList.push({
                        original: mediaPathPrefix + mediaList[i].MediaUrl,
                        thumbnail: mediaPathPrefix + mediaList[i].MediaUrl
                    });
                } else {
                    galleryList.push({
                        original: mediaPathPrefix + mediaList[i].ThumbnailUrl,
                        thumbnail: mediaPathPrefix + mediaList[i].ThumbnailUrl,
                        embedUrl: mediaPathPrefix + mediaList[i].MediaUrl,
                        renderItem: this._renderVideo.bind(this)
                    });

                }
            }

            this.images = galleryList;
        }

        return (<ImageGallery
            ref={this.imageGallery}
            items={this.images}
            showPlayButton={false}
            lazyload={true}
            onSlide={
                (index) => {
                    this.setState({ mediaIndexDisplayed: index });
                }
            }
        />
        );
    }

    

    renderBlogEntry(blogEntry) {

        var blogEntryDate = new Date(blogEntry.Date);

        var selectedMarkerId = -1;

        try {
            selectedMarkerId = blogEntry.MediaList[this.state.mediaIndexDisplayed].MarkerId;
        } catch (e) {
            selectedMarkerId = -1;
        }

        var id = parseInt(this.props.match.params.id, 10);

        return (
            <div>
                {this.renderWidthWarningIfNeeded()}
                <div style={{ position: "absolute", margin: "15px", top: "0", right: "0", bottom: "0", left: "0", width: "47%", height: "92%" }}>
                    <div>
                        <table>
                            <tr>
                                <td width="200">
                                    <button onClick={() => { this.props.history.goBack() }} style={{ backgroundColor: "#202020", border: "none", fontSize:"large" }}>
                                        &lt;&lt; Back
                                    </button>
                                </td>
                            </tr>
                        </table>
                    </div>
                    <div style={{ position: "absolute", margin: "15px", top: "40px", right: "0", bottom: "0", left: "0", width: "100%", height: "46%" }}>
                        {this.renderCarousel(blogEntry.MediaList)}
                    </div>
                    <div style={{ position: "absolute", margin: "15px", top: "60%", right: "0", bottom: "0", left: "0", width: "100%", height: "38%", minHeight:"250px", fontSize: "large" }}>
                        <div style={{ overflow: "auto", height: "100%" }}>
                            <header>
                                <h1>
                                    {blogEntry.Title}
                                </h1>
                                <div>
                                    <time dateTime={blogEntryDate}>
                                        {blogEntryDate.toLocaleString('default', { month: 'long', year: 'numeric', day: 'numeric' })}
                                    </time>
                                </div>
                            </header>
                            {ReactHtmlParser(blogEntry.Text)}
                        </div>
                    </div>
                </div>
                <div style={{ position: "absolute", margin: "15px", top: "0", right: "0", bottom: "0", left: "50%", width: "47%", height: "92%" }}>
                    <MyMapComponent
                        markerList={blogEntry.MarkerList}
                        selectedMarkerId={selectedMarkerId}
                        onMarkerClick={this.onMarkerClick}
                        onMarkerClickContext={this}
                        googleMapURL={`https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=${ApiKey}`}
                        loadingElement={<div style={{ height: `100%` }} />}
                        containerElement={<div style={{ height: `100%` }} />}
                        mapElement={<div style={{ height: `100%` }} />}
                    />
                </div>
            </div>
        );
    }

    render() {

        var id = parseInt(this.props.match.params.id, 10);

        if (id > 0) {

            if (this.props.getBlogEntry.isLoading) {
                return (
                    <div style={blogEntryLoadingStyle}>
                        <ul>
                            <p>Loading...</p>
                            <p><img alt="loading" src={loading} style={{ maxHeight: 200, maxWidth: 200 }} /></p>
                        </ul>
                    </div>
                );
            }

            var blogEntryList = this.props.getBlogEntry.blogEntryList;

            if (Array.isArray(blogEntryList) && blogEntryList.length > 0) {

                var blogEntry = null;
                var i;

                for (i = 0; i < blogEntryList.length; i++) {
                    if (blogEntryList[i].Id === id) {
                        blogEntry = blogEntryList[i];
                        break;
                    }
                }

                if (blogEntry === null) {
                    return (<p>Error loading blog entry :( </p>);
                }

                return (
                    <div style={rootBlogEntryStyle} >
                        {this.renderBlogEntry(blogEntry)}
                    </div>
                );
            }
        }

        return (
            <div style={rootBlogEntryStyle} >
                <p> Bad blog id! </p>
            </div>
        );
    }
}


export default connect(
    (state) => {
        const { getBlogEntry } = state;
        return {
            getBlogEntry
        };
    },

    dispatch => {
        return {
            getBlogEntryActionCreators: bindActionCreators(getBlogEntryActionCreators, dispatch)
        };
    }
)(BlogEntry);