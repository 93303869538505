const requestStaticBackgroundImagesType = 'REQUEST_BACKGROUND_IMAGES_INFO';
const receiveStaticBackgroundImagesType = 'RECEIVE_BACKGROUND_IMAGES_INFO';
const initialState = { backgroundImages: [], isLoading: false, isLoaded: false };

const staticBackgroundImagesArray = [
    "00201_bn_17aq5896f80296.jpg",
    "00236_bn_17aq5896f80331.jpg",
    "00501_bn_17aq5896f80596_z.jpg",
    "00502_bn_17aq5896f80597_z.jpg",
    "Sequoia.jpg",
    "134255_469163622460_3292800_o.jpg",
    "aug00.jpg",
    "dsc_0008.jpg",
    "dsc_0057.jpg",
    "FH010030.jpg",
    "IMG_20180819_091205.jpg",
    "IMG_20190420_070113.jpg",
    "IMG_20190628_123032.jpg",
    "IMG_20190720_204813.jpg",
    "IMG_20190811_121520.jpg",
    "IMG_20190812_091247.jpg",
    "IMG_20190812_122827.jpg",
    "IMG_20190813_124706.jpg",
    "IMG_20200310_075309.jpg",
    "IMG_20200311_075727.jpg",
    "IMG_20200313_114819.jpg",
    "n_v19aq5893m50732.jpg",
    "n_v19aq5893m51612.jpg",
    "selit68-R3-E055.jpg"
];



export const backgroundImagesActionCreators = {
    requestStaticBackgroundImages: () => async (dispatch, getState) => {

        if (getState().backgroundImages.isLoading || getState().backgroundImages.isLoaded) {
            // Don't keep adding more dispatches...
            return;
        }

        dispatch({ type: requestStaticBackgroundImagesType });

        dispatch({ type: receiveStaticBackgroundImagesType, backgroundImages: staticBackgroundImagesArray });
    }
};

export const reducer = (state, action) => {
  state = state || initialState;

    if (action.type === requestStaticBackgroundImagesType) {
    return {
        ...state,
        isLoading: true
    };
  }

    if (action.type === receiveStaticBackgroundImagesType) {
    return {
      ...state,
        backgroundImages: action.backgroundImages,
        isLoading: false,
        isLoaded: true
    };
  }

  return state;
};
