import React, { Component } from 'react';
import { HashLink as Link } from 'react-router-hash-link';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import ReactHtmlParser from 'react-html-parser';
import { updateListActionCreators } from '../store/UpdateList';
import { Accordion, Button, ListGroup, ListGroupItem } from 'react-bootstrap';
import Card from 'react-bootstrap/Card';
import loading from './loading.gif';

var theBlogRootStyle = {
    color: '#eee',
    minHeight: '100vh',
    margin: '15px',
    fontSize: "x-large"
}

var theBlogLoadingStyle = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    color: '#eee',
    margin: '15px',
    fontSize: "x-large"
}

const monthIndexToString = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];


function renderImageSection(blogEntrySummary) {
    if (blogEntrySummary.UpdateDisplayImageUrl) {

        var imagePath = "https://seanandthepctstorage.blob.core.windows.net/blogphotos/" + blogEntrySummary.UpdateDisplayImageUrl;

        return (
            <div style={{ height: "300px" }}>
                <Link to={`/blogentry/${blogEntrySummary.Id}`}>
                    <img data-src={imagePath}
                        data-image={imagePath}
                        data-image-focal-point="0.5,0.5"
                        alt="Blog Entry"
                        style={{ height: "100%" }}
                        src={imagePath} />
                </Link>
            </div>
        );
    }

    return;
}


class TheBlog extends Component {

    state = {
        windowSize: window.innerWidth,
        orderedUpdateList: "Loading...",
        journalList: []
    };

    componentDidMount() {
        // This method runs when the component is first added to the page
        this.props.updateListActionCreators.requestUpdateList();

        window.addEventListener('resize', this.handleWindowSizeChange);

        this.updateInternalStateIfPossible();
    }

    componentDidUpdate() {
        this.props.updateListActionCreators.requestUpdateList();

        this.updateInternalStateIfPossible();
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleWindowSizeChange);
    }

    handleWindowSizeChange = () => {
        this.setState({ windowSize: window.innerWidth });
    };


    renderWidthWarningIfNeeded() {
        if (this.state.windowSize < 700) {
            return (
                <div style={{ width: "100%", color: "#eee", fontSize: "large" }}>
                    <p> Better viewed in landscape mode :) </p>
                </div>
            );
        }
    }

    updateInternalStateIfPossible() {
        if (Array.isArray(this.props.updateList.updateList) && this.props.updateList.updateList.length > 0 && !Array.isArray(this.state.orderedUpdateList)) {

            // create an ordered list of all the blog entries, from newest to oldest

            var orderedUpdateList = [...this.props.updateList.updateList];

            for (var i = 0; i < orderedUpdateList.length; i++) {
                orderedUpdateList[i].UpdateDate = new Date(orderedUpdateList[i].UpdateDate);
                orderedUpdateList[i].UpdateDateText = orderedUpdateList[i].UpdateDate.toLocaleString('default', { month: 'long', year: 'numeric', day: 'numeric' });

                orderedUpdateList[i].ReadMore = orderedUpdateList[i].UpdateText.length > 500;

                if (orderedUpdateList[i].ReadMore) {
                    orderedUpdateList[i].UpdateText = orderedUpdateList[i].UpdateText.slice(0, 500) + "...";
                }

                orderedUpdateList[i].ReadMore = true;
            }

            orderedUpdateList = orderedUpdateList.sort((a, b) => (b.UpdateDate) - (a.UpdateDate));

            // Now create a list of each each year, where under each year is each month that has a blog entry, and then each month has a list of entries

            var journalList = [];

            for (i = 0; i < orderedUpdateList.length; i++) {

                // for each blog entry, first see if there is already a journal entry for that year.
                var journalIndex = -1;

                var blogEntry = orderedUpdateList[i];

                for (var j = 0; j < journalList.length; j++) {
                    if (blogEntry.UpdateDate.getFullYear() === journalList[j].year) {
                        journalIndex = j;
                        break;
                    }
                }

                var journalEntry = {};

                if (journalIndex === -1) {
                    // Add a new year to the journalList
                    journalEntry.year = blogEntry.UpdateDate.getFullYear();
                    journalEntry.months = [];
                    journalList.push(journalEntry);
                } else {
                    journalEntry = journalList[journalIndex];
                }

                // Now we have the journal entry, let's check if there is already an item for the month

                var monthIndex = -1;

                for (var m = 0; m < journalEntry.months.length; m++) {
                    if (blogEntry.UpdateDate.getMonth() === journalEntry.months[m].month) {
                        monthIndex = m;
                        break;
                    }
                }

                var monthEntry = {};

                if (monthIndex === -1) {
                    // Add a new month to the journalList
                    monthEntry.month = blogEntry.UpdateDate.getMonth();
                    monthEntry.updates = [];
                    journalEntry.months.push(monthEntry);
                } else {
                    monthEntry = journalEntry.months[monthIndex];
                }

                // Ok, now we have the month, last step - add this to the list of updates in that month

                var updateItem = {};

                updateItem.date = blogEntry.UpdateDate.getDate();
                updateItem.updateTitle = blogEntry.UpdateTitle;
                updateItem.id = blogEntry.Id;

                monthEntry.updates.push(updateItem);
            }

            this.setState({ orderedUpdateList: orderedUpdateList, journalList: journalList });
        } else if (((typeof this.state.orderedUpdateList === 'string') || (this.state.orderedUpdateList instanceof String)) &&
            ((typeof this.props.updateList.updateList === 'string') || (this.props.updateList.updateList instanceof String)) &&
            (this.state.orderedUpdateList.localeCompare(this.props.updateList.updateList) != 0)) {
            this.setState({ orderedUpdateList: this.props.updateList.updateList });
        }
    }

    renderUpdateList() {

        if (!Array.isArray(this.state.orderedUpdateList)) {
            if (this.props.updateList.isLoading) {
                return (<ul style={{ paddingInlineStart: "0" }}> Loading... <p><img alt="loading" src={loading} style={{ maxHeight: 200, maxWidth: 200 }} /></p></ul>);
            } else {
                return (<ul style={{ paddingInlineStart: "0" }}> { this.state.orderedUpdateList} </ul>)
            }
        }

        return (
            <ul style={{ paddingInlineStart: "0" }}>
                {
                    this.state.orderedUpdateList.map(item => {
                        return (
                            <article id={"article-" + item.Id} key={"key-" + item.Id} data-item-id={item.Id}>
                                <header>
                                    <h1>
                                        {item.UpdateTitle}
                                    </h1>
                                    <div>
                                        <time dateTime={item.UpdateDate}>
                                            {item.UpdateDateText}
                                        </time>
                                    </div>
                                </header>
                                <div className="postHeaderDividerStyle"></div>
                                {renderImageSection(item)}
                                <div>
                                    {ReactHtmlParser(item.UpdateText)}
                                    <p>
                                        {item.ReadMore ? <Link to={`/blogentry/${item.Id}`}>Read More</Link> : ""}
                                    </p>
                                </div>
                                <footer>
                                </footer>
                                <hr />
                            </article>
                        );
                    })
                }
            </ul>
        );
    }

    render() {

        return (
            <div style={theBlogRootStyle} >
                {this.renderWidthWarningIfNeeded()}
                <div hidden>
                    <h1>Welcome to the section containing all my blog entries from section hikes and (eventually) the actual PCT thru-hike.</h1>
                </div>


                <table style={{ tableLayout: "fixed" }}>
                    <tbody>
                        <tr>
                            <td style={{ width: "80%", minHeight: "85vh", borderRight:"solid", borderRightColor:"#eee" }}>
                                <div style={{ margin: "15px" }}>
                                    { this.renderUpdateList() }
                                </div>
                            </td>
                            <td style={{ textAlign: "left", verticalAlign: "top", fontSize: "large" }}>
                                <p style={{margin: "0px 0px 10px 5px"}}>
                                    Blog Entries:
                                </p>
                                <Accordion>
                                    {
                                        this.state.journalList.map(item => {
                                            return (
                                                <Card key={"cardKey" + item.year.toString() }>
                                                    <Card.Header>
                                                        <Accordion.Toggle as={Button} variant="link" eventKey={item.year.toString()} style={{ fontSize: "large" }}>
                                                            {item.year}
                                                        </Accordion.Toggle>
                                                    </Card.Header>
                                                    <Accordion.Collapse eventKey={item.year.toString()}>
                                                        <Card.Body>
                                                            <Accordion>
                                                                {
                                                                    item.months.map(monthInfo => {
                                                                        return (
                                                                            <Card key={"cardKey" + item.year.toString() + monthInfo.month.toString() } >
                                                                                <Card.Header>
                                                                                    <Accordion.Toggle as={Button} variant="link" eventKey={item.year.toString() + monthInfo.month.toString()} style={{ fontSize: "large", margin: "0px 0px 0px 15px"  }}>
                                                                                        {monthIndexToString[monthInfo.month]}
                                                                                    </Accordion.Toggle>
                                                                                </Card.Header>
                                                                                <Accordion.Collapse eventKey={item.year.toString() + monthInfo.month.toString()}>
                                                                                    <ListGroup>
                                                                                        {
                                                                                            monthInfo.updates.map(entry => {
                                                                                                return (
                                                                                                    <ListGroupItem style={{ backgroundColor: "#202020", borderColor: "#202020" }} key={ entry.id }>
                                                                                                        <Link to={"/theblog#article-" + entry.id}>
                                                                                                            <p style={{ margin: "0px 0px 0px 30px" }}>
                                                                                                                {monthIndexToString[monthInfo.month] + " " + entry.date} : {entry.updateTitle}
                                                                                                            </p>
                                                                                                        </Link>
                                                                                                    </ListGroupItem>

                                                                                                );
                                                                                            })}
                                                                                    </ListGroup>
                                                                                </Accordion.Collapse>
                                                                            </Card>
                                                                        );
                                                                    })}
                                                            </Accordion>
                                                        </Card.Body>
                                                    </Accordion.Collapse>
                                                </Card>
                                            );
                                        })}
                                </Accordion>
                            </td>
                        </tr>
                    </tbody>
                </table>                
            </div>
        );
    }
}


export default connect(

    (state) => {
        const { updateList } = state;
        return {
            updateList
        };
    },

    dispatch => {
        return {
            updateListActionCreators: bindActionCreators(updateListActionCreators, dispatch)
        };
    }
)(TheBlog);