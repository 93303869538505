import React from 'react';
import { Route } from 'react-router';
import Layout from './components/Layout';
import Home from './components/Home';
import Sean from './components/Sean';
import WhyPCT from './components/WhyPCT';
import PrePCTWork from './components/PrePCTWork';
import Gear from './components/Gear';
import WhereIsSean from './components/WhereIsSean';
import ThePlan from './components/ThePlan';
import BlogEntry from './components/BlogEntry';
import TheBlog from './components/TheBlog';
import MyPack from './components/MyPack';


export default () => (
  <Layout>
    <Route exact path='/' component={Home} />
    <Route path='/sean' component={Sean} />
    <Route path='/whypct' component={WhyPCT} />
    <Route path='/prepctwork' component={PrePCTWork} />
    <Route path='/mypack' component={MyPack} />
    <Route path='/gear' component={Gear} />
    <Route path='/theplan' component={ThePlan} />
    <Route path='/whereissean' component={WhereIsSean} />
    <Route path='/theblog' component={TheBlog} />
    <Route path='/blogentry/:id' component={BlogEntry} />
  </Layout>
);
