import React, { Component } from 'react';
import { connect } from 'react-redux';


var seanRootStyle = {
    color: '#eee',
    minHeight: '100vh',
    margin: '15px',
    fontSize: "x-large",
    backgroundColor: "#202020"
}


var indentStyle = {
    marginLeft: "20px"
}

class Sean extends Component {

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render() {
        return (
            <div style={seanRootStyle}>
                <div hidden>
                    <h1>Welcome to the section all about me, how my passion grew about backpacking, camping and led me to the PCT.</h1>
                </div>

                
                <a href={process.env.PUBLIC_URL + '/images/IMG_20190812_122827.jpg'}>
                    <img src={process.env.PUBLIC_URL + '/images/IMG_20190812_122827.jpg'} style={{ float: "left", margin: "10px", maxWidth: "375px" }} />
                </a>

                <p><strong>Who is this Sean-person?</strong> &nbsp;</p>

                <p style={indentStyle}>Well, like most people, I am a complex mix. &nbsp;I was born in Southern California and spent about half my growing up years there. &nbsp;The rest of the time we were moving about, Madison, Wisc., Freehold, NJ and Golden, CO. &nbsp;I spent Junior High and High School in Golden, so a good chunk of me is that country-music loving, small-town in the Rockies, get into the mountains person.</p>
                <p style={indentStyle}><br /></p>
                <p style={indentStyle}>My SoCal personality is very surfer-dude. &nbsp;I spent a ton of my summers body-surfing and generally hanging out at the beach, biking the boardwalk in Venice and chilling.</p>
                <p style={indentStyle}><br /></p>
                <p style={indentStyle}>I went to university at Carnegie-Mellon University (Pittsburgh, PA - a great city!) and then graduate school at University of Wisconsin-Madison. &nbsp;I eventually ended up working at Microsoft in Seattle, WA.</p>
                <p style={indentStyle}><br /></p>
                <p style={indentStyle}>At 30 years old, I quit Microsoft along with my girlfriend. &nbsp;We bought a sailboat (catamaran) and moved to the Bahamas with our two cats. &nbsp;A few years later I needed a job again, so went back to work at Microsoft, having been there ever since.</p>
                <p style={indentStyle}><br /></p>
                <p style={indentStyle}>Now I&apos;m in my mid-50s, married above my paygrade and we have two great kids.</p>
                <p style={indentStyle}><br /></p>
                <p style={indentStyle}>With the kids we started camping and then I started backpacking again.  I did a few section hikes in Washington and picked up my trail name, "Tigger".  But more on me and the PCT in the Why PCT section :)</p>
                <p style={indentStyle}><br /></p>
                <p><strong>Some of the crazy adventures I&apos;ve been on:</strong></p>
                <p style={indentStyle}>After graduating from CMU I did the &apos;backpack around Europe&apos; thing - solo. &nbsp;I&apos;d never been to Europe, but what the heck, why not!? &nbsp;After two days I was ready to quit, after a week I didn&apos;t want it to end. &nbsp;One of my best friends ever is from meeting in the youth hostel in Venice, Italy. &nbsp;I experience Pink Floyd in an altered state in Vienna, non-altered in Berne, visited the lapland of Finland, and met family members for the first time in France. &nbsp;It was amazing.</p>
                <p style={indentStyle}><br /></p>
                <p style={indentStyle}>Two years later I solo bicycled France and Switzerland for 5 weeks. &nbsp;I loaded up all my camping gear on to a bike and just headed out. &nbsp;I cycled all over northern and middle France, then over the Alps into Switzerland.</p>
                <p style={indentStyle}><br /></p>
                <p style={indentStyle}>A couple of years later I found myself signing up for a sailing trip in Greece with a 12 people I didn&apos;t know. &nbsp;Many of them are still my very bestest friends. &nbsp;Did a repeat of the sailing thing in 1995, but this time in Turkey and Greece - amazing stuff. &nbsp;Really did a big repeat of the sailing thing when buying our own boat and doing the Bahamas, plus Turks and Caicos.</p>
                <p style={indentStyle}><br /></p>
                <p style={indentStyle}>I&apos;ve climbed Mt Baker, WA and Mt Kilimanjaro in Tanzania. &nbsp;Rendez-vous&apos;d with friends for an African safari, and built homes for Habitat for Humanity in Embangweni, Malawi.</p>
                <p style={indentStyle}><br /></p>
                <p style={indentStyle}>For a few years I was visiting Cuba quite often - what an amazing country and people. I am very happy to have met and made friends down there. &nbsp;One of my amazing trips was driving every road West of Havana to the far Western tip of Cuba - and, no, I don&apos;t speak Spanish :)</p>
                <p style={indentStyle}><br /></p>
                <p style={indentStyle}>Guatemala is amazing. &nbsp;Spent two weeks traveling around by chicken-bus with backpacks back-and-forth across the whole country back in 1997. &nbsp;This was before Guatemala was a common destination, so we were often the only two non-natives. &nbsp;Totally cool.</p>
                <p style={indentStyle}><br /></p>
                <p style={indentStyle}>My wife and I have sailed Belize, lived in Shanghai, China, traveled in Asia, Europe, Africa and New Zealand. &nbsp;We were married on a beach in Mexico - beautiful scene with the sun setting, with our family and friends. &nbsp;In Morocco, we rode camels into the desert at night so that we could watch the sunrise over the Sahara. &nbsp;Amazing.</p>
                <p style={indentStyle}><br /></p>
                <p style={indentStyle}>Now, let's be honest about all these adventures.  To truly be an 'adventure', there is some risk, pushing-the-comfort-zone aspect.  In nearly all of these adventures there has been some point where I felt "this sucks, I'm outta here".  It's part of the deal, really.  So, please don't get the idea that all of these things I've done were pure fun - I definitely got more than I imagined from each, but at some point each was challenging to a degree.</p>
                <p style={indentStyle}><br /></p>
                <p style={indentStyle}>Ok, wow, writing it all down makes it seem like a lot - and maybe it is. &nbsp;But there&apos;s still life in me yet!</p>
                <p style={indentStyle}><br /></p>
            </div>
        );
    }
}

export default connect()(Sean);