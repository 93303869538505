const requestUpdateListType = 'REQUEST_UPDATE_LIST';
const receiveUpdateListType = 'RECEIVE_UPDATE_LIST';
const initialState = { updateList: [], isLoading: false, isLoaded: false, errorCount: 0 };

import staticUpdateList from './BlogEntries.json';


export const updateListActionCreators = {
  requestUpdateList: (tries) => async (dispatch, getState) => {    
    if (getState().updateList.isLoading || getState().updateList.isLoaded) {
        // Don't keep adding more dispatches...
        return;
    }

        dispatch({ type: requestUpdateListType });

        console.log("Getting static update list...");

        var updateList = staticUpdateList;
                
        dispatch({ type: receiveUpdateListType, updateList: updateList });
  }
};

export const reducer = (state, action) => {
  state = state || initialState;

  if (action.type === requestUpdateListType) {
    return {
        ...state,
        isLoading: true,
        updateList: 'Loading...'
    };
  }

  if (action.type === receiveUpdateListType) {
    return {
      ...state,
      updateList: action.updateList,
      isLoading: false,
      isLoaded: true
    };
  }

  return state;
};
