import React, { Component } from 'react';
import { HashLink as Link } from 'react-router-hash-link';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { backgroundImagesActionCreators } from '../store/StaticBackgroundImages';
import { updateListActionCreators } from '../store/UpdateList';
import loading from './loading.gif';
import './Home.css';

var rootStyle = {
    color: '#eee',
    minHeight: '100vh',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundImage: ''
}

class Home extends Component {

    state = {
        timer: null,
        indexOfImageToShow: -1, 
        orderedUpdateList: "Loading ...",
        cAdditionalBlogEntries: 0,
        windowWidth: window.innerWidth
    };

    componentDidMount() {
        // This method runs when the component is first added to the page
        this.props.backgroundImagesActionCreators.requestStaticBackgroundImages();
        this.props.updateListActionCreators.requestUpdateList();

        sessionStorage.setItem('timerInterval', 1000);
        let timer = setInterval(this.tick.bind(this), 1000);
        this.setState({ timer });

        var currentIndex = this.state.indexOfImageToShow;

        if (currentIndex === -1) {
            currentIndex = parseInt(sessionStorage.getItem('indexOfImageToShow') || '-1', 10);

            if (currentIndex !== -1) {
                this.setState({ indexOfImageToShow: currentIndex });
            }
        }

        this.updateInternalStateIfPossible();

        window.scrollTo(0, 0);
    }

    componentDidUpdate() {
        this.props.backgroundImagesActionCreators.requestStaticBackgroundImages();
        this.props.updateListActionCreators.requestUpdateList();

        this.updateInternalStateIfPossible();
    }

    componentWillUnmount() {
        clearInterval(this.state.timer);
    }

    updateInternalStateIfPossible() {
        if (Array.isArray(this.props.updateList.updateList) && this.props.updateList.updateList.length > 0 && !Array.isArray(this.state.orderedUpdateList)) {
            var orderedUpdateList = [...this.props.updateList.updateList];

            for (var i = 0; i < orderedUpdateList.length; i++) {
                orderedUpdateList[i].UpdateDate = new Date(orderedUpdateList[i].UpdateDate);
                orderedUpdateList[i].UpdateDateText = orderedUpdateList[i].UpdateDate.toLocaleString('default', { month: 'long', year: 'numeric', day: 'numeric' });
            }

            orderedUpdateList = orderedUpdateList.sort((a, b) => (b.UpdateDate) - (a.UpdateDate));

            if (orderedUpdateList.length > 5) {
                var cMore = orderedUpdateList.length - 5;
                this.setState({ cAdditionalBlogEntries: cMore })
                orderedUpdateList = orderedUpdateList.slice(0, 5);
            }

            this.setState({ orderedUpdateList: orderedUpdateList });
        } else if (((typeof this.state.orderedUpdateList === 'string') || (this.state.orderedUpdateList instanceof String)) &&
            ((typeof this.props.updateList.updateList === 'string') || (this.props.updateList.updateList instanceof String)) &&
            (this.state.orderedUpdateList.localeCompare(this.props.updateList.updateList) != 0)) {
            this.setState({ orderedUpdateList: this.props.updateList.updateList });
        }
    }


    tick() {
        if (this.props.backgroundImages.backgroundImages.length > 0) {

            var currentIndex = this.state.indexOfImageToShow;
            var timerInterval = parseInt(sessionStorage.getItem('timerInterval'), 10);

            // If this is the first time thru the Component.
            if (timerInterval === 1000) {

                timerInterval = 10000;
                sessionStorage.setItem('timerInterval', timerInterval);

                clearInterval(this.state.timer);
                this.setState({ timer: setInterval(this.tick.bind(this), timerInterval) });
                
                // If we've been displaying images previously, then
                // don't advance the image yet - redisplay the previous image.
                if (currentIndex !== -1) {
                    return;
                }
            }

            if (currentIndex >= (this.props.backgroundImages.backgroundImages.length - 1)) {
                this.setState({indexOfImageToShow: 0});
            } else {
                this.setState({ indexOfImageToShow: currentIndex + 1 });
            }

            sessionStorage.setItem('indexOfImageToShow', this.state.indexOfImageToShow);
        }  
    }

    renderCountAdditionalBlogEntries() {
        if (this.state.cAdditionalBlogEntries > 0) {
            return (
                <p><br />
                    and &nbsp;
                    <Link to={`/theblog`}>
                        {this.state.cAdditionalBlogEntries.toString()} more...
                    </Link>
                    &nbsp; :)
                </p>
            );
        }

        return (
            <p><br />
            </p>
        );
    }


    renderUpdateList() {
        if (!Array.isArray(this.state.orderedUpdateList)) {
            if (this.props.updateList.isLoading) {
                return (<ul> <img alt="loading" src={loading} style={{ maxHeight: 100, maxWidth: 100 }} /></ul>);
            } else {
                return (<ul> {this.state.orderedUpdateList} </ul>)
            }
        }

        return (<ul>
            { this.state.orderedUpdateList.map(item => {
                return <li className="updateListItemStyle" key={item.Id}>
                    <Link to={`/blogentry/${item.Id}`}>
                        {item.UpdateDateText + ": " + item.UpdateTitle}
                    </Link>
                </li>;
            })}
            {this.renderCountAdditionalBlogEntries()}
        </ul>
        );
    }


    render() {

        let displayStyle = { ...rootStyle };

        if (this.state.indexOfImageToShow !== -1) {
            displayStyle["backgroundImage"] = "url(\"" + process.env.PUBLIC_URL + "/images/backgrounds/" + this.props.backgroundImages.backgroundImages[this.state.indexOfImageToShow] + "\")";
        }
               
        return (
            <div style={displayStyle}>
                <div className="updateListStyle">
                    <p className="updateListHeaderStyle">
                        <strong>First and foremost, thanks to my wife, Megan, for making this all possible.</strong>
                    </p>
                    <p className="updateListHeaderStyle">
                        If any of this has inspired you to go after some long-sought goal, or donate to a cause, or anything else, please drop me an email, text or leave a comment.
                    </p>
                    <p className="updateListHeaderStyle">Latest Blog Entries:</p>
                        {this.renderUpdateList()}
                </div>
            </div>
        );
    }
}

export default connect(

    (state) => {
        const { backgroundImages, updateList } = state;
        return {
            backgroundImages,
            updateList
        };
    },

    dispatch => {
        return {
            backgroundImagesActionCreators: bindActionCreators(backgroundImagesActionCreators, dispatch),
            updateListActionCreators: bindActionCreators(updateListActionCreators, dispatch)
        };
    }
)(Home);