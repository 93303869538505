import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Container, ListGroup, ListGroupItem, Card } from 'react-bootstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome, faUser, faQuestion, faUniversity, faList, faCompass, faCalendar, faHiking, faBlog, faBars, faLightbulb } from '@fortawesome/free-solid-svg-icons';

var navMenuItemStyle = {
    cursor: "pointer",
    color: "#eee",
    fontSize: "large",
    textDecoration: "none" 
}



class MyNavMenu extends Component {


    state = {
        isExpanded: false,
        windowHeight: window.innerHeight,
        windowWidth: window.innerWidth
    };


    componentDidMount() {
        // This method runs when the component is first added to the page

        window.addEventListener('resize', this.handleWindowSizeChange);
    }


    componentWillUnmount() {
        window.removeEventListener('resize', this.handleWindowSizeChange);
    }


    handleWindowSizeChange = () => {
        this.setState({ windowWidth: window.innerWidth, windowHeight: window.innerHeight });
    };

    decideIfExpanded(context) {

        if (context.state.windowWidth >= 1080) {
            return true;
        }

        return context.state.isExpanded;
    }

    closeNavMenu(context) {
        context.setState({ isExpanded: false });
    }

    onNavigationToggleClick(context) {
        console.log("flipping Panel");
        context.setState({ isExpanded: !context.state.isExpanded });
    }

    renderNavCard() {

        if (!this.decideIfExpanded(this)) {
            return (
                <div>
                </div>
            );
        }

        return (
            <Card>
                <Card.Body>
                    <ListGroup>
                        <ListGroupItem style={{ backgroundColor: "#202020", borderColor: "#202020" }}>
                            <Link style={navMenuItemStyle} to="/" onClick={(e) => { this.closeNavMenu(this) }}>
                                <FontAwesomeIcon icon={faHome} /> Home
                            </Link>
                        </ListGroupItem>
                        <ListGroupItem style={{ backgroundColor: "#202020", borderColor: "#202020" }}>
                            <Link style={navMenuItemStyle} to="/sean" onClick={(e) => { this.closeNavMenu(this) }}>
                                <FontAwesomeIcon icon={faUser} /> Who is Sean?
                            </Link>
                        </ListGroupItem>
                        <ListGroupItem style={{ backgroundColor: "#202020", borderColor: "#202020" }}>
                            <Link style={navMenuItemStyle} to="/mypack" onClick={(e) => { this.closeNavMenu(this) }}>
                                <FontAwesomeIcon icon={faHiking} /> My Current Pack
                            </Link>
                        </ListGroupItem>
                        <ListGroupItem style={{ backgroundColor: "#202020", borderColor: "#202020" }}>
                            <Link style={navMenuItemStyle} to="/gear" onClick={(e) => { this.closeNavMenu(this) }}>
                                <FontAwesomeIcon icon={faList} /> Gear Reviews
                            </Link>
                        </ListGroupItem>
                        <ListGroupItem style={{ backgroundColor: "#202020", borderColor: "#202020" }}>
                            <Link style={navMenuItemStyle} to="/whypct" onClick={(e) => { this.closeNavMenu(this) }}>
                                <FontAwesomeIcon icon={faQuestion} /> Why the PCT?
                            </Link>
                        </ListGroupItem>
                        <ListGroupItem style={{ backgroundColor: "#202020", borderColor: "#202020" }}>
                            <Link style={navMenuItemStyle} to="/prepctwork" onClick={(e) => { this.closeNavMenu(this) }}>
                                <FontAwesomeIcon icon={faUniversity} /> Pre-PCT Learning
                            </Link>
                        </ListGroupItem>
                        <ListGroupItem style={{ backgroundColor: "#202020", borderColor: "#202020" }}>
                            <Link style={navMenuItemStyle} to="/theplan" onClick={(e) => { this.closeNavMenu(this) }}>
                                <FontAwesomeIcon icon={faCalendar} /> The Plan
                            </Link>
                        </ListGroupItem>
                        <ListGroupItem style={{ backgroundColor: "#202020", borderColor: "#202020" }}>
                            <Link style={navMenuItemStyle} to="/whereissean" onClick={(e) => { this.closeNavMenu(this) }}>
                                <FontAwesomeIcon icon={faCompass} /> Where is Sean?
                            </Link>
                        </ListGroupItem>
                        <ListGroupItem style={{ backgroundColor: "#202020", borderColor: "#202020" }}>
                            <Link style={navMenuItemStyle} to="/theblog" onClick={(e) => { this.closeNavMenu(this) }}>
                                <FontAwesomeIcon icon={faBlog} /> From The Trail Blog
                            </Link>
                        </ListGroupItem>
                    </ListGroup>
                </Card.Body>
            </Card>
        );
    }
    
    render() {
        return (
            <Container style={{ maxWidth: "250px" }}>
                <div style={{ display: "flex", justifyContent: "flex-start" }}>
                    {this.state.windowWidth < 1080 ?
                        <button
                            style={{ border: "none", backgroundColor: "#202020", top: "0", paddingLeft: "10px" }}
                            onClick={() => { this.onNavigationToggleClick(this) }}
                        >
                            <FontAwesomeIcon icon={faBars} inverse size="1x" /></button> :
                        <div></div>
                    }
                    <Link to={'/'} style={{ color: "#eee", fontSize: "x-large", paddingLeft: "10px" }}>SeanAndThePCT</Link>
                </div>
                { this.renderNavCard()}
            </Container>
        );
    }
}

export default connect()(MyNavMenu);