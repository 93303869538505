import React, { Component } from 'react';
import Collapsible from 'react-collapsible';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { gearCategoryInfoActionCreators } from '../store/StaticGearCategoryInfo';
import { gearItemInfoActionCreators } from '../store/StaticGearItemInfo';
import './Gear.css';


var rootStyle = {
    color: '#eee',
    minHeight: '100vh',
    margin: '15px'
}


var tableStyleOverrides = {
    marginLeft: '30px',
    marginRight: '15px',
    width: 'auto',
    cursor: 'default'
}


var triggerStyleOverrides = {
    marginLeft: '30px',
    marginRight: '15px',
    width: 'auto',
    cursor: 'pointer'
}


class Gear extends Component {


    state = {
        windowSize: window.innerWidth
    };

    componentDidMount() {
        // This method runs when the component is first added to the page
        this.props.gearCategoryActions.requestStaticGearCategoryInfo();
        this.props.gearItemActions.requestStaticGearItemInfo();

        window.addEventListener('resize', this.handleWindowSizeChange);

        window.scrollTo(0, 0);
    }

    componentDidUdpate() {
        this.props.gearCategoryActions.requestStaticGearCategoryInfo();
        this.props.gearItemActions.requestStaticGearItemInfo();
    }


    componentWillUnmount() {
        window.removeEventListener('resize', this.handleWindowSizeChange);
    }

    handleWindowSizeChange = () => {
        this.setState({ windowSize: window.innerWidth });
    };


    renderWidthWarningIfNeeded() {
        if (this.state.windowSize < 700) {
            return (
                <div style={{ color: "#eee", fontSize: "large" }}>
                    <p> Better viewed in landscape mode :) </p>
                </div>
            );
        }

        return (<div> </div>);
    }


    render() {

        return (
            <div style={rootStyle}>
                <h1>Gear reviews</h1>
                {this.renderWidthWarningIfNeeded()}
                {renderGearInfo(this.props, true)}
            </div>
        );
    }
}




function gearCategoryElement(categoryId, categoryName, categoryWeight) {
    this.CategoryId = categoryId;
    this.CategoryName = categoryName;
    this.CategoryWeight = categoryWeight / 16;
}

function renderGearInfo(props) {

    let orderedGearCategoryInfo = [];
    let categoryList = [];

    let gearCategoryInfo = props.gearCategoryInfo.gearCategoryInfo;
    let gearItemInfo = props.gearItemInfo.gearItemInfo;

    if ((gearCategoryInfo.length > 0) && (gearItemInfo.length > 0)) {

        categoryList = new Array(gearCategoryInfo.length);

        // first sort the category table
        orderedGearCategoryInfo = gearCategoryInfo.sort((a, b) => (a.CategoryOrder) - (b.CategoryOrder));

        // Now create a new category array from this one, with ID, Name, Weight fields 
        for (var i = 0; i < orderedGearCategoryInfo.length; i++) {

            var categoryWeight = 0.0;

            categoryList[i] = new gearCategoryElement(
                orderedGearCategoryInfo[i].CategoryId,
                orderedGearCategoryInfo[i].CategoryName,
                categoryWeight);
        }

        // if so, then create a new item array with just the items in the pack
    }



    return (

        <div>
            <div hidden>
                <h1>Page for reviews of any and all gear I have bought or used</h1>
            </div>
            {
                categoryList.map(gearCategory =>
                    <Collapsible trigger={" + " + gearCategory.CategoryName + " Reviews"}
                        triggerWhenOpen={" - " + gearCategory.CategoryName + " Reviews"}
                        triggerStyle={triggerStyleOverrides}
                        key={"gear-collapsible-" + gearCategory.CategoryName}
                    >
                        <table className='table' style={tableStyleOverrides} key="gear-table">
                            <thead>
                                <tr className='gear-tr' key="gear-table-header">
                                    <th>Type</th>
                                    <th></th>
                                    <th>Description</th>
                                    <th>Weight</th>
                                    <th>Review</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    gearItemInfo.filter(gearItem => (gearItem.CategoryId === gearCategory.CategoryId))
                                        .sort(function (a, b) {
                                            if (a.CategoryOrder > b.CategoryOrder) {
                                                return 1;
                                            }
                                            if (a.CategoryOrder < b.CategoryOrder) {
                                                return -1;
                                            }
                                            if (a.ItemOrder > b.ItemOrder) {
                                                return 1;
                                            }
                                            return -1;
                                        })
                                        .map(gearItem =>
                                            <tr key={gearItem.ItemId} className='gear-tr'>
                                            <td>{gearItem.ItemType}</td>
                                                <td>
                                                    {gearItem.Photo1Id.length > 4 ?
                                                        <img alt="1" src={process.env.PUBLIC_URL + '/images/gear/CategoryId' + gearItem.CategoryId + '/' + gearItem.Photo1Id} style={{ maxHeight: 150, maxWidth: 150 }} /> :
                                                        ""}
                                                    {gearItem.Photo2Id.length > 4 ?
                                                        <img alt="2" src={process.env.PUBLIC_URL + '/images/gear/CategoryId' + gearItem.CategoryId + '/' + gearItem.Photo2Id} style={{ maxHeight: 150, maxWidth: 150 }} /> :
                                                        ""}
                                            </td>
                                            <td><a className='gear-a' href={gearItem.Url}> {gearItem.Description} </a></td>
                                            <td>{gearItem.Weight + " oz."}</td>
                                            <td>{gearItem.Notes}</td>
                                        </tr>
                                    )
                                }
                            </tbody>
                        </table>
                    </Collapsible>
            )}
        </div>
  );
}



export default connect(
    (state) => {
        const { gearCategoryInfo, gearItemInfo } = state;
        return {
            gearCategoryInfo,
            gearItemInfo
        };
    },

    dispatch => {
        return {
            gearCategoryActions: bindActionCreators(gearCategoryInfoActionCreators, dispatch),
            gearItemActions: bindActionCreators(gearItemInfoActionCreators, dispatch)
        };
    }
)(Gear);
