import React, { Component } from 'react';
import { connect } from 'react-redux';

var whyPCTRootStyle = {
    color: '#eee',
    minHeight: '100vh',
    margin: '15px',
    fontSize: 'x-large'
}

class WhyPCT extends Component {

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render() {
        return (
            <div style={whyPCTRootStyle} >
                <div hidden>
                    <h1>Welcome! This section dives into why I've chosen to thru-hike the PCT specifically.</h1>
                </div>

                <a href={process.env.PUBLIC_URL + '/images/il_fullxfull.771843125_3yhc.jpg'}>
                    <img src={process.env.PUBLIC_URL + '/images/il_fullxfull.771843125_3yhc.jpg'} style={{ float: "left", margin: "5px", maxWidth: "375px" }} />
                </a>

                <p>I can&apos;t remember ever not camping. &nbsp;We were always out in the woods, along the beach, or Yosemite and Sequoia. &nbsp;It was cheap, we kids got to run around the woods and throw rocks at each other, climb tall trees, sword fight with long pointy sticks and all those things you can&apos;t do in today&apos;s world. &nbsp;</p>
                <p><br /></p>
                <p>We started backpacking when we moved to Wisconsin. &nbsp;I was 8 years old. &nbsp;We&apos;d drive up to Door County and backpack in. &nbsp;Being a kid, it seemed like a long hike in, but for all I know it could have been a half-a-mile. &nbsp;I remember we didn&apos;t have whatever they called proper backpacks back then. &nbsp;We&apos;d roll up our sleeping bags and run a rope thru them, then wear that across our shoulders. &nbsp;We&apos;d have our school book bags stuffed with clothes and whatever else could fit. &nbsp;I remember being in an outhouse and raccoons chomping on the outside of it :) &nbsp;It rained a couple of times too. &nbsp;</p>
                <p><br /></p>
                <p>We&apos;d camp a lot too. &nbsp;Easier and could cart more stuff in our VW bus. &nbsp;With four kids and such, we needed the space. &nbsp;We&apos;d pack every inch of that bus over the next decade. &nbsp;When we moved to Colorado it was combinations of road trips and camping, or we&apos;d use Uncle Mike&apos;s Jeep and go 4x4 camping high up in the Rockies. &nbsp;Find a nice lake, swim, eat over the campfire, ... all goodness. &nbsp;Somewhere in this time frame is when we got those aluminum framed backpacks (red, of course). &nbsp;You can see a picture of me and my siblings in the Home page photo rotation. &nbsp;</p>
                <p><br /></p>
                <p>When I was in college, about once a semester, I&apos;d take off on a Friday and backpack in somewhere. &nbsp;Bring a pile of books and tea bags, but no food - the books and gear took up all the space. &nbsp;I&apos;d read for 48 hours in the middle of nowhere woods, then drive back to school. &nbsp;Very rejuvenating.</p>
                <p><br /></p>
                <p>I continued backpacking and camping. &nbsp;I&apos;d bring a tent went visiting my Bahamian friends and just sleep on the beach. &nbsp;My bike-camping trip across France, for example. &nbsp;</p>
                <p><br /></p>
                <p>However, I pretty much stayed with weekend backpacking as my longest outings. &nbsp;</p>
                <p><br /></p>
                <p>Then a friend of mine got into &quot;ultralight&quot; backpacking. &nbsp;He is, and was, so passionate about it, he&apos;d talk about cutting down his toothbrush to shave tenths-of-ounces. &nbsp;He talked about having a pack that weighed in less than 5 lbs. &nbsp;He and some friends circumnavigated Mt Rainier in just a handful of days. &nbsp;Which got me interested in this idea of lighter, less-grueling, backpacking. &nbsp;But I really didn&apos;t do anything about it.</p>
                <p><br /></p>
                <p>Then, like many, I happened across &quot;Wild&quot; in 2014. &nbsp;I really identified with the backpacking, the clunky gear, the aluminum backpack, etc. &nbsp;So I started reading about this thing called the Pacific Crest Trail. &nbsp;Much of it went thru areas that are a deep part of my life. &nbsp;It starts in SoCal, as I did. &nbsp;It winds up thru all the places I camped in my youth. &nbsp;It ends up in Washington, just like I have. &nbsp;So I guess I immediately felt some kind of parallel with my own life. &nbsp;(The really tough Sierras line-up well with my tough 20s, too)</p>
                <p><br /></p>
                <p>Then I started to think about putting aside this PCT thing. &nbsp;But my mind kept coming back to it. &nbsp;Over the next two years I wandered in and out of learning about the PCT, following stories, reading, whatever. &nbsp;The more I tried to burn myself out on this crazy idea, the more it stuck with me. &nbsp;After a long while I finally admitted to my wife I had this crazy notion. &nbsp;Part of me was thinking that by verbalizing it, I would hear how silly it sounded and that would be that.</p>
                <p><br /></p>
                <p>It didn&apos;t quite work out that way. &nbsp;I started researching gear. &nbsp;I started putting together a &apos;virtual&apos; pack, where I listed all the gear I thought I needed and put it into a &apos;pack&apos; on <a href="//lighterpack.com">lighterpack.com</a>. &nbsp;I sent my virtual pack to my ultralight friends and got some, well, we&apos;ll call it &quot;valuable feedback&quot;. &nbsp;My first attempt at a pack was 25+ lbs!</p>
                <p><br /></p>
                <p>I started taking packs on section hikes - maybe this would scratch that itch and I&apos;d be done - but, no. &nbsp;I started dialing in my gear. &nbsp;(see the &quot;My Current Pack&quot; and &quot;Pre-PCT Learning&quot; for lots of details on how I got to my current pack, the trade-off&apos;s I&apos;ve made and my current gear list. &nbsp;&quot;Gear Reviews&quot; has every everything I&apos;ve ever tried and what I think about each one)</p>
                <p><br /></p>
                <p>So, over the past few years my family has gotten the idea that this might actually happen.</p>
            </div>
        );
    }
}


export default connect()(WhyPCT);