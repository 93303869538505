import React, { Component } from 'react';
import { connect } from 'react-redux';

var rootStyle = {
    color: '#eee',
    minHeight: '100vh',
    margin: '15px',
    fontSize: 'large'
}

var iframeStyle = {
    minHeight: '95vh',
    minWidth: '95%'
}

class WhereIsSean extends Component {

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render() {
        return (
            <div style={rootStyle} >
                <div hidden>
                    <h1>Welcome to the section that shows you exactly where I am at the moment :)</h1>
                </div>


                <br/>
                <p>
                    You may want to click 'View All Tracks' on the map below, otherwise you only see the current day.
                </p>
                <p>
                    If you'd like to join me for a day, have a lunch/breakfast/beer at a trail crossing the road, or meet up in some town - feel free to surprise me!!
                    It is the little surprises and trail magic that will help keep my spirits up.
                </p>
                <p>
                    If you are thinking about
                    doing a few days, a section, or longer with me, that could work too.  I'll be doing about 20 miles per day,
                    and you will have to be self-sufficient, or give me enough warning so that I can gear up for your visit :)
                </p>
                <br/>
                <iframe title="Where is Sean?" src={'https://share.garmin.com/SeanSe'} style={iframeStyle} />
            </div>
        );
    }
}


export default connect()(WhereIsSean);