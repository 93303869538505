const requestStaticGearItemInfoType = 'REQUEST_GEAR_ITEM_INFO';
const receiveStaticGearItemInfoType = 'RECEIVE_GEAR_ITEM_INFO';
const initialState = { gearItemInfo: [], isLoading: false, isLoaded: false };

const staticGearItemDataArray = [
    {
        "ItemId": 1,
        "CategoryId": 1,
        "ItemType": "Pack",
        "ItemOrder": 1,
        "Description": "Gossamer Gear Gorilla 40L",
        "Url": "https://www.gossamergear.com/products/gorilla-ultralight-backpack-all-bundle",
        "IsWornClothing": false, 
        "IsConsumable": false,
        "IsArchived" : false,
        "Weight": 31.8,
        "Quantity": 1,
        "Notes": "The Gorilla is my primary pack because it is great, plus 10 oz lighter than the ULA Circuit.  The " +
            "weight difference is not insignificant and Gorilla has a built in sit pad, while I have to carry a sit pad when taking the Circuit - thus " +
            "a full pound lighter in the end.  I prefer the Circuit, but it's a tough call for when I don't need the volume, " +
            "so I am biased towards saving the weight.  I will use the Circuit for the Sierras, but most of the PCT will be with this guy.",
        "Photo1Id": "GORILLA_40_FRONT_1600x.jpg",
        "Photo2Id": ""
    }, {
        "ItemId": 2,
        "CategoryId": 1,
        "ItemType": "Pack",
        "ItemOrder": 2,
        "Description": "ULA Circuit",
        "Url": "https://www.ula-equipment.com/product/circuit/?avad=183186_b1487d4e1",
        "IsWornClothing": false,
        "IsConsumable": false,
        "IsArchived": true,
        "Weight": 41.0,
        "Quantity": 1,
        "Notes": "This pack is awesome. It is so comfortable, nice adjustable straps everywhere, bomb-proof, nice hip belt with storage, " +
            "huge capacity and super comfy.  All that comes with a weight price, and with my age I need/want a sit pad, so I have to carry that as well.  Thus, " +
            "I will use this pack when I'm in the Sierras, but go with the Gorilla everywhere else.  The comfort will help carry that extra " +
            "weight for the bear vault, needed extra food for the long stretches, etc.",
        "Photo1Id": "ULA-Circuit-Left-Side-View.jpg",
        "Photo2Id": ""
    }, {
        "ItemId": 3,
        "CategoryId": 1,
        "ItemType": "Pack",
        "ItemOrder": 3,
        "Description": "Zpacks Arc Zip Haul",
        "Url": "https://zpacks.com/products/arc-haul-zip-backpack",
        "IsWornClothing": false,
        "IsConsumable": false,
        "IsArchived": true,
        "Weight": 25.7,
        "Quantity": 1,
        "Notes": "This was the first pack I tried.  It shocked me in how light it was, how much I could fit inside and the cool carbon structs that " +
            "create the air flow behind the back.  I used this on my first section hike.  It is crazy light, but I found that I had to add shoulder and " +
            "belt storage, which added weight.  Then I found the belt itself to be a bit light on the padding.  After reading some of HalfMiles PCT gear " +
            "reviews, many thru-hikers found this bag to break down over the 2,000+ miles.  So I decided to expand my pack search to other bags.",
        "Photo1Id": "arc-haul-zip-moss-angle-l_2048x.jpg",
        "Photo2Id": ""
    }, {
        "ItemId": 4,
        "CategoryId": 1,
        "ItemType": "Pack",
        "ItemOrder": 4,
        "Description": "Zpacks Arc Zip",
        "Url": "https://zpacks.com/products/arc-zip-backpack",
        "IsWornClothing": false,
        "IsConsumable": false,
        "IsArchived": true,
        "Weight": 23.1,
        "Quantity": 1,
        "Notes": "I breifly tried the smaller cousin to the Zip Arc Haul.  I found it fit better (for me), but I had the same reservations as with the Zip Arc Haul.  " +
           "I also found that although I thought I would LOVE the Zip access, I found that I was ok accessing everything from the top.",
        "Photo1Id": "arc-zip-azure-angle-l_2048x.jpg",
        "Photo2Id": ""
    }, {
        "ItemId": 6,
        "CategoryId": 1,
        "ItemType": "Tent",
        "ItemOrder": 6,
        "Description": "Zpacks Duplex",
        "Url": "https://zpacks.com/products/duplex-tent",
        "IsWornClothing": false,
        "IsConsumable": false,
        "IsArchived": true,
        "Weight": 19.0,
        "Quantity": 1,
        "Notes": "This was the first tent I tried in my new found gusto for ultra-light.  I went straight for the single-wall, light, but still a two-person tent.  I " +
            "used this on my first section hike in the Pacific Northwest, during a sunny and nice weekend.  It was nice, small, light, but I had a couple of problems " +
            "with it.  First, for reasons explained before I use a 3-inch sleeping pad.  Plus being 6 feet tall, means that with the way this tent pitches, my nose and " +
            "footbox touched the interior of tent roof all night.  This made me pretty damp by morning due to my breathing.  Sadness.  I really want to like this tent, but " +
            "my tallness works against me.",
        "Photo1Id": "duplex-blue-angle-l_2048x.jpg",
        "Photo2Id": "IMG_20190419_180312.jpg"
    }, {
        "ItemId": 7,
        "CategoryId": 1,
        "ItemType": "Tent",
        "ItemOrder": 7,
        "Description": "Big Agnes Scout 2 Carbon",
        "Url": "https://www.bigagnes.com/Scout-2-Carbon",
        "IsWornClothing": false,
        "IsConsumable": false,
        "IsArchived": true,
        "Weight": 11.0,
        "Quantity": 1,
        "Notes": "Semi-sold on single-wall tents, I went for the Scout 2 Carbon.  The pitch line is parallel to the sleeping line, so this means that my head would " +
            "not hit the roof and the usable square footage would be better.  And it's ELEVEN OUNCES!!  IT'S ELEVEN OUNCES FOR A TWO PERSON TENT!!  Overall, I really, " +
            "really loved this tent.  As a single-wall tent, I'd want this one " +
            "to have a vestibule :(  I was very sad to not have one, but there is soooo much room I could pull all my gear inside and cook inside as well!  In the " +
            "end I moved away from this tent because of the single-wall aspect.  I got caught in a monsoon during a section hike and the condensation inside the tent " +
            "rained down on me all night from the rain hitting the roof and shaking it.  If I take a single-wall, this is the tent I will take.",
        "Photo1Id": "TSC219_Carbon_door closed-001.jpg",
        "Photo2Id": ""
    }, {
        "ItemId": 8,
        "CategoryId": 1,
        "ItemType": "Tent",
        "ItemOrder": 8,
        "Description": "Big Agnes Tiger Wall UL2",
        "Url": "https://www.bigagnes.com/Tiger-Wall-UL2",
        "IsWornClothing": false,
        "IsConsumable": false,
        "IsArchived": true,
        "Weight": 38.8,
        "Quantity": 1,
        "Notes": "Having tried the single-wall tents, I moved into the double-wall realm.  I started with this one and love it!  My daughter and I use it often, it's wonderful amount " +
            "of space makes for easy living, no condensation in the inner tent, all goodness.  So, at this point, I decided to the weight difference is worth it on the long thru-hike " +
            "for the comfort of knowing me and all my stuff will be dry no matter what - and I get vestibules.  In the end, tho, this is not the double-wall tent I'm taking on my PCT thru-hike.",
        "Photo1Id": "TTWUL218_Tiger Wall UL 2-006.jpg",
        "Photo2Id": "IMG_20200404_203909.jpg"
    }, {
        "ItemId": 9,
        "CategoryId": 1,
        "ItemType": "Tent",
        "ItemOrder": 9,
        "Description": "Stratospire 2 Li",
        "Url": "https://www.tarptent.com/product/stratospire-li/",
        "IsWornClothing": false,
        "IsConsumable": false,
        "IsArchived": false,
        "Weight": 27.0,
        "Quantity": 1,
        "Notes": "This is the tent.  Huge.  Super easy to pitch.  Incredibly light for a double-wall.  Vestibules and a boat load of venting.  This is my tent for the thru-hike.  I've " +
            "spent the night in sub-freezing weather and the only condensation was on the inside of the fly.  I took the solid inner, barely any moisture inside the solid inner even in " +
            "the sub-freezing weather.  The tent pitches low to the ground, even in a big downpour I got no up-splash on the inner.  Bingo!",
        "Photo1Id": "ssli_door_open-1.jpg",
        "Photo2Id": ""
    }, {
        "ItemId": 10,
        "CategoryId": 1,
        "ItemType": "Tent footprint",
        "ItemOrder": 10,
        "Description": "Stratospire/Scout 2 footprint",
        "Url": "https://www.bigagnes.com/Scout-2-Carbon-Footprint",
        "IsWornClothing": false,
        "IsConsumable": false,
        "IsArchived": false,
        "Weight": 3.0,
        "Quantity": 1,
        "Notes": "I use a footprint for two reasons.  First, reduce wear-and-tear on the inner of my tent.  Second, it keeps the dirt and mud off the bottom of the inner - meaning I " +
            "put the inner away clean and dry inside my pack every day, while the fly and footprint I carry on the outside of the pack to keep the dirt and water out of the pack.  A " +
            "nice side-effect is that it's easy to get at them to dry them out mid-day and to set up the fly-only at the end of the day.  This is the footprint for the Scout 2, but works " +
            "great for the Stratospire as well.  I have a specialized footprint for the Tiger Wall UL2, as it has grommits for setting up the poles with a fly-only pitch option.",
        "Photo1Id": "TFFSC219_Footprint-001.jpg",
        "Photo2Id": ""
    }, {
        "ItemId": 11,
        "CategoryId": 1,
        "ItemType": "Tent footprint",
        "ItemOrder": 11,
        "Description": "Tiger Wall UL2 footprint",
        "Url": "https://www.bigagnes.com/Tiger-Wall-UL2-Footprint",
        "IsWornClothing": false,
        "IsConsumable": false,
        "IsArchived": true,
        "Weight": 6.0,
        "Quantity": 1,
        "Notes": "I use this footprint for the same reasons described in my thoughts on footprints.  The Stratospire allows a fly-only pitch without the footprint, but the Tiger Wall requires a " +
            "special footprint to do a fly-only pitch, which is why I have this footprint as well.",
        "Photo1Id": "TFFTWUL218_TigerWallUL2-Footprint.jpg",
        "Photo2Id": ""
    }, {
        "ItemId": 12,
        "CategoryId": 1,
        "ItemType": "Tent stakes",
        "ItemOrder": 12,
        "Description": "MSR CarbonCore tent stakes",
        "Url": "https://www.msrgear.com/tents/tent-accessories/carboncore-tent-stakes/02499.html",
        "IsWornClothing": false,
        "IsConsumable": false,
        "IsArchived": false,
        "Weight": 0.2,
        "Quantity": 8,
        "Notes": "I've tried many stakes, but have settled on these for now.  A good combination of lightweight and strong.  I've read reviews that they might break when needing to be bashed by " +
            "a rock or the like.  I haven't had that problem yet, so I'm sticking with these guys.",
        "Photo1Id": "02499_msr_carboncore_stakes_group_02.jpg",
        "Photo2Id": ""
    }, {
        "ItemId": 13,
        "CategoryId": 1,
        "ItemType": "Tent stakes",
        "ItemOrder": 13,
        "Description": "Zpacks Carbon Fiber tent stakes",
        "Url": "https://zpacks.com/products/carbon-fiber-stake",
        "IsWornClothing": false,
        "IsConsumable": false,
        "IsArchived": true,
        "Weight": 0.22,
        "Quantity": 8,
        "Notes": "These were my first attempt at lightweight tent stakes and they just didn't work for me longterm.  Maybe they degrade over time, but the year after I bought them, 3 broke " +
            "just by pushing them into stiff ground with my foot.  Something easily handled previously, or with other stakes.  So, I gave up on these.",
        "Photo1Id": "zpacks-6point4-inch-carbon-fiber-tent-stake_800x.jpg",
        "Photo2Id": ""
    }, {
        "ItemId": 14,
        "CategoryId": 1,
        "ItemType": "Tent stakes",
        "ItemOrder": 14,
        "Description": "Zpacks Ultralight Titanium stakes",
        "Url": "https://zpacks.com/products/6-titanium-hook-stake-w-orange-head",
        "IsWornClothing": false,
        "IsConsumable": false,
        "IsArchived": true,
        "Weight": 0.19,
        "Quantity": 8,
        "Notes": "I gave these a shot and almost immediately did not like them.  The shafts are so thin, I have no confidence in them.  The holding power of the thin shaft is " +
            "suspect in soft ground, so I shelved these almost immediately.",
        "Photo1Id": "zpacks-6point5-inch-red-tough-titanium-stake_2048x.jpg",
        "Photo2Id": ""
    }, {
        "ItemId": 15,
        "CategoryId": 1,
        "ItemType": "Sleeping quilt",
        "ItemOrder": 15,
        "Description": "Enlightened Enigma 20F X-long, X-wide",
        "Url": "https://enlightenedequipment.com/enigma-quilts/",
        "IsWornClothing": false,
        "IsConsumable": false,
        "IsArchived": false,
        "Weight": 24.45,
        "Quantity": 1,
        "Notes": "The weight here is with stuff sack and straps for the sleeping pad.  I decided to experiment with a quilt for the first time after reading extensively.  For me " +
            "this has been great.  I am a person that tosses and turns alot all night, sleeping on both my sides and stomach.  I am not a back sleeper.  As such, the quilt is a " +
            "great choice.  I am especially happy that I experimented with the long/wide version, as in the snow I can hide completely under the quilt and stay warm.  Attaching " +
            "to the sleeping pad took a while for me to work out what is best for me (turns out I am a 'wrap it under the pad' person).  I sleep a bit warmer, so the 20F bag is perfect " +
            "for snow, while the quilt nature makes it easy to adjust for warmer nights.",
        "Photo1Id": "SleepingBag01.jpg",
        "Photo2Id": ""
    }, {
        "ItemId": 16,
        "CategoryId": 1,
        "ItemType": "Sleeping quilt",
        "ItemOrder": 16,
        "Description": "Enlightened Enigma 40F regular, sewn footbox",
        "Url": "https://enlightenedequipment.com/enigma-quilts/",
        "IsWornClothing": false,
        "IsConsumable": false,
        "IsArchived": true,
        "Weight": 15.8,
        "Quantity": 1,
        "Notes": "The weight here is with stuff sack and straps for the sleeping pad.  I loved the long/wide quilt so much I stayed with Enlightened, experimenting with a sewn " +
            "footbox, regular size and less aggressive temperature rating.  Turns out that the result is still a really good sleeping quilt, but I still prefer the larger, warmer " +
            "choice.  I do use this quilt on occassion, during hot summer night camping trips.",
        "Photo1Id": "SleepingBag02.jpg",
        "Photo2Id": ""
    }, {
        "ItemId": 17,
        "CategoryId": 1,
        "ItemType": "Sleeping pad",
        "ItemOrder": 17,
        "Description": "Sea-to-Summit Ultralight Air (regular)",
        "Url": "https://seatosummitusa.com/collections/sleeping-mats/products/ultralight-mat",
        "IsWornClothing": false,
        "IsConsumable": false,
        "IsArchived": true,
        "Weight": 13.9,
        "Quantity": 1,
        "Notes": "I started with the Sea-to-Summit sleeping pad, taking it on a weekend trip with a friend.  This pad is LOUD.  As I've said elsewhere, I toss and turn " +
            "alot at night, and every movement on this pad results in squeaking and crinkling sounds.  So much so that my friend in the tent next door complained about it " +
            "the next morning.  So I moved away from this pad.",
        "Photo1Id": "Ultralight_Sleeping_Mat_Air_Pad_Regular_Awards_2048x.progressive.jpg",
        "Photo2Id": ""
    }, {
        "ItemId": 18,
        "CategoryId": 1,
        "ItemType": "Sleeping pad",
        "ItemOrder": 18,
        "Description": "Therma-rest Zlite (small)",
        "Url": "https://www.thermarest.com/sleeping-pads/fast-and-light/z-lite-sol-and-original-z-lite/z-lite.html",
        "IsWornClothing": false,
        "IsConsumable": false,
        "IsArchived": true,
        "Weight": 10.0,
        "Quantity": 1,
        "Notes": "I played around with the Zlite as my pad for a few adventures.  As a side and stomach sleeper I found the pad to be too hard to sleep well.  Then I went " +
            "on an overnight in the snow and it was a miserable night.  The insulation value is ugly, 2.0, and combined with the lack of cushioning in the pad, I decided that " +
            "this was not the pad for me.",
        "Photo1Id": "OIP.jpg",
        "Photo2Id": ""
    }, {
        "ItemId": 19,
        "CategoryId": 1,
        "ItemType": "Sleeping pad",
        "ItemOrder": 19,
        "Description": "Neo Tensor Ultralight (insulated, regular)",
        "Url": "https://www.nemoequipment.com/product/tensor/",
        "IsWornClothing": false,
        "IsConsumable": false,
        "IsArchived": true,
        "Weight": 15.0,
        "Quantity": 1,
        "Notes": "I really liked the Tensor.  It is quiet, great comfy.  However, like others, I had quality problems with the two I purchased.  The first had very small holes in " +
            "it that I had to patch, then I'd inflate to solid firmness, use overnight, then another pin-hole.  The second one I had blew out a baffle while sleeping on it in my " +
            "car.  I lost confidence in the Tensor and so ended up throwing them out rather than continuing to struggle with them.",
        "Photo1Id": "z9hdtzqpntswojfbr8ca-1768x1496.jpg",
        "Photo2Id": ""
    }, {
        "ItemId": 20,
        "CategoryId": 1,
        "ItemType": "Sleeping pad",
        "ItemOrder": 20,
        "Description": "Therma-rest NeoAir XTherm (regular)",
        "Url": "https://www.thermarest.com/sleeping-pads/fast-and-light/neoair-xtherm-sleeping-pad/neoair-xtherm.html",
        "IsWornClothing": false,
        "IsConsumable": false,
        "IsArchived": false,
        "Weight": 16.3,
        "Quantity": 1,
        "Notes": "I should have just bought this based on the Halfmile PCT survey results at the start, but then if I had I'd probably have still wondered about lighter options.  This " +
            "is my pad.  Great insulation (R6.9), comfy, quiet and bomb-proof.  I've used it on many weekenders and CA Section A - I don't worry about having to coddle it.",
        "Photo1Id": "10481908x1012464_zm.jpg",
        "Photo2Id": ""
    }, {
        "ItemId": 21,
        "CategoryId": 1,
        "ItemType": "Pillow",
        "ItemOrder": 21,
        "Description": "Sea-to-Summit Aero Ultralight Pillow (large)",
        "Url": "https://seatosummitusa.com/collections/inflatable-camping-pillows/products/aeros-pillow-ultra-light",
        "IsWornClothing": false,
        "IsConsumable": false,
        "IsArchived": true,
        "Weight": 2.5,
        "Quantity": 1,
        "Notes": "My first foray into inflated pillows and i really didn't like them.  It just felt like I was sleeping on a balloon no matter how inflated or not I made it.  If " +
            "I was able to sleep on my back, I can imagine this would be a very good option, but as I move around alot at night, I found it just not the right answer for me.",
        "Photo1Id": "573-49_AerosUltralightPillow_Regular_SeaFoam_01_ForWeb_2048x.progressive.jpg",
        "Photo2Id": ""
    }, {
        "ItemId": 22,
        "CategoryId": 1,
        "ItemType": "Pillow",
        "ItemOrder": 22,
        "Description": "Zpacks Pillow Dry Bag (medium)",
        "Url": "https://zpacks.com/products/medium-pillow",
        "IsWornClothing": false,
        "IsConsumable": false,
        "IsArchived": true,
        "Weight": 1.4,
        "Quantity": 1,
        "Notes": "I tried out using a stuff sack as a pillow.  This seems to work for many people, stuffing your down jacket, hat, other warm weather gear into a lined bag to " +
            "create a pillow on the fly.  Personally I found it kind of lumpy and ill formed, wishing for just something a little different.",
        "Photo1Id": "zpacks-medium-pillow-dry-bag_ccfe4348-9954-472f-8df7-3113d22c1cb5_2048x.jpg",
        "Photo2Id": ""
    }, {
        "ItemId": 23,
        "CategoryId": 1,
        "ItemType": "Pillow",
        "ItemOrder": 23,
        "Description": "Goosefeet Down Pillow (large)",
        "Url": "https://goosefeetgear.com/products/down-pillows/",
        "IsWornClothing": false,
        "IsConsumable": false,
        "IsArchived": false,
        "Weight": 5.3,
        "Quantity": 1,
        "Notes": "The Goosefeet pillow I got is awesome.  They custom make to your specfications on size and down fill.  I went with " +
            "the medium pillow right off the bat, but increased to the large.  It has an integrated stuff sack, which is great, and the down layer tends to blend out any lumpiness from stuffing " + 
            "clothing inside.  I went with a light fill in my medium pillow, but upgraded to a 4 oz fill with the large.  Love it.  Love it!",
        "Photo1Id": "PillowMedium.jpg",
        "Photo2Id": ""
    }, {
        "ItemId": 24,
        "CategoryId": 1,
        "ItemType": "Backpack cover",
        "ItemOrder": 24,
        "Description": "SilNylon Backpack Cove",
        "Url": "https://www.ula-equipment.com/product/pack-covers/",
        "IsWornClothing": false,
        "IsConsumable": false,
        "IsArchived": false,
        "Weight": 3.0,
        "Quantity": 1,
        "Notes": "Getting caught in a monsoon during a PCT section hike really emphasized for me how much I needed to invest protecting myself and gear from " +
            "getting completely soaked.  As part of that investment I decided to carry the extra weight of a pack cover.  I haven't used it yet, but I'll be " +
            "carrying it.",
        "Photo1Id": "ULA-Pack-Cover-On.jpg",
        "Photo2Id": ""
    }, {
        "ItemId": 25,
        "CategoryId": 2,
        "ItemType": "Stove",
        "ItemOrder": 25,
        "Description": "MSR Pocket Rocket 2",
        "Url": "https://www.msrgear.com/stoves/canister-stoves/pocketrocket-2-stove/09884.html",
        "IsWornClothing": false,
        "IsConsumable": false,
        "IsArchived": false,
        "Weight": 2.6,
        "Quantity": 1,
        "Notes": "The PCT requires using stoves with a valve controller (read: no alcohol stoves allowed anymore).  So I went with the MSR Pocket Rocket.  It's " +
            "amazing.  Buy it, lament that you have to carry canisters, get over it and move on to other things to worry about. :)",
        "Photo1Id": "OIP (1).jpg",
        "Photo2Id": ""
    }, {
        "ItemId": 26,
        "CategoryId": 2,
        "ItemType": "Stove",
        "ItemOrder": 26,
        "Description": "Trail Designs Caldera Cone alcohol stove",
        "Url": "https://www.traildesigns.com/products/caldera-cone-system",
        "IsWornClothing": false,
        "IsConsumable": false,
        "IsArchived": true,
        "Weight": 3.3,
        "Quantity": 1,
        "Notes": "The PCT requires using stoves with a valve controller (read: no alcohol stoves allowed anymore).  For awhile, I used an alcohol stove for " +
            "awhile on weekend trips.  First, if you don't know what you're doing, it can be scary - too much alcohol gets really hot and nothing to do but " +
            "wait for it to burn out.  In the end, the weight difference versus a fuel container system made it not worth it to me.",
        "Photo1Id": "caldera04-500_large.jpg",
        "Photo2Id": ""
    }, {
        "ItemId": 27,
        "CategoryId": 2,
        "ItemType": "Lighter",
        "ItemOrder": 27,
        "Description": "Outdoor Element Firebiner",
        "Url": "https://outdoorelement.com/products/firebiner-carabiner?variant=32840122466348",
        "IsWornClothing": false,
        "IsConsumable": false,
        "IsArchived": false,
        "Weight": 1.0,
        "Quantity": 1,
        "Notes": "I use this tool now for a variety of reasons.  First, my wife gave it to me as a gift, so I carry something from her.  Also, the carabiner " +
            "allows me to easily hang my Garmin Inreach Mini in front on my shoulder straps.  I usually start my walking in the morning with a cup of " +
            "coffee in hand, so this allows me to hang the empty cup easily as well.  I took the blade off, because I didn't want to accidentally slice any straps or lines.",
        "Photo1Id": "Firebiner-Silver_1024x1024@2x.jpg",
        "Photo2Id": ""
    }, {
        "ItemId": 28,
        "CategoryId": 2,
        "ItemType": "Lighter",
        "ItemOrder": 28,
        "Description": "BIC Mini lighter",
        "Url": "https://www.bicworld.com/en/our-products/lighters",
        "IsWornClothing": false,
        "IsConsumable": false,
        "IsArchived": true,
        "Weight": 0.4,
        "Quantity": 1,
        "Notes": "I used to use a BIC Mini, carried two in case one ran out of fluid.  TIP: Don't worry about the fluid, the flint sparker can still be used " +
            "to spark light your stove.  Then you can replenish at your next stop.",
        "Photo1Id": "mini-bic-lighter-camping.jpg",
        "Photo2Id": ""
    }, {
        "ItemId": 29,
        "CategoryId": 2,
        "ItemType": "Cooking Pot",
        "ItemOrder": 29,
        "Description": "TOAKS 450ml Titanium Cup",
        "Url": "https://www.toaksoutdoor.com/collections/cup80/products/cup-450-c",
        "IsWornClothing": false,
        "IsConsumable": false,
        "IsArchived": true,
        "Weight": 3.5,
        "Quantity": 1,
        "Notes": "This is the only cup/pot I've ever used.  It's rugged, holds my cookset, except the fuel, and does the job.  My only consideration is that it is " +
            "exactly 2 cups, which means boil over, which can burn - or often a dehydrated meal will need more than 2 cups and I have a slightly crunchy meal.  " +
            "So, I moved up in capacity to the 650ml pot.",
        "Photo1Id": "61j5pZnHZKL._AC_SL1133_.jpg",
        "Photo2Id": ""
    }, {
        "ItemId": 30,
        "CategoryId": 2,
        "ItemType": "Cooking Utensil",
        "ItemOrder": 30,
        "Description": "MSR Spork",
        "Url": "https://www.msrgear.com/cookware/camp-kitchen-and-utensils/folding-utensils/folding-utensils.html",
        "IsWornClothing": false,
        "IsConsumable": false,
        "IsArchived": false,
        "Weight": 0.3,
        "Quantity": 1,
        "Notes": "I bought this as my first utensil, it works great, folds into the cup, awesome.",
        "Photo1Id": "293111_8615_XL.jpg",
        "Photo2Id": ""
    }, {
        "ItemId": 31,
        "CategoryId": 2,
        "ItemType": "Utility Knife",
        "ItemOrder": 31,
        "Description": "Ladybug utility knife",
        "Url": "https://spyderco.com/catalog/details.php?product=262",
        "IsWornClothing": false,
        "IsConsumable": false,
        "IsArchived": false,
        "Weight": 0.6,
        "Quantity": 1,
        "Notes": "This knife is awesome.  Super light.  Super sharp.  Folding, so I don't worry about slicing things open accidentally.  Love this knife and " +
            "super thankful to my friend for recommending this guy.",
        "Photo1Id": "LBKS3_Both.jpg",
        "Photo2Id": ""
    }, {
        "ItemId": 32,
        "CategoryId": 2,
        "ItemType": "Cooking set stuff sack",
        "ItemOrder": 32,
        "Description": "Hyperlite stuff sack (small, DCF8)",
        "Url": "https://www.hyperlitemountaingear.com/products/drawstring-stuff-sacks",
        "IsWornClothing": false,
        "IsConsumable": false,
        "IsArchived": false,
        "Weight": 0.28,
        "Quantity": 1,
        "Notes": "I keep my entire cookset, including fuel cannister in this bag.  Fits everything nice and tidy in a touch sack with drawstring.",
        "Photo1Id": "hyperlite-mountain-gear-drawstring-stuff-sacks-2357360656429.jpg",
        "Photo2Id": ""
    }, {
        "ItemId": 33,
        "CategoryId": 2,
        "ItemType": "Water filtration",
        "ItemOrder": 33,
        "Description": "Sawyer Squeeze",
        "Url": "https://sawyer.com/products/sawyer-squeeze-filter/",
        "IsWornClothing": false,
        "IsConsumable": false,
        "IsArchived": false,
        "Weight": 3.45,
        "Quantity": 1,
        "Notes": "The Sawyer Squeeze is the most popular option for a very good reason.  It is pretty bomb proof, easy to backflush and requires zero thought.  It " +
            "has good flow rate, does the job.  Don't over think this - buy it and be amazed.  TIP: trim off some of the extra plastic from the pull top rim, this " +
            "will ensure that when you stick your Sawyer into a narrow opening there is room for the air to escape as the bottle fills with water.",
        "Photo1Id": "Sawyer-Squeeze-Water-Filter-unit-with-cap-2.jpg",
        "Photo2Id": ""
    }, {
        "ItemId": 34,
        "CategoryId": 2,
        "ItemType": "Water filtration",
        "ItemOrder": 34,
        "Description": "Sawyer Squeeze Micro",
        "Url": "https://sawyer.com/products/sawyer-micro-squeeze-water-filtration-system/",
        "IsWornClothing": false,
        "IsConsumable": false,
        "IsArchived": true,
        "Weight": 2.0,
        "Quantity": 1,
        "Notes": "In an effort to further reduce weight, I tried out the Micro.  The flow rate was super slow, so I went back to the full size Squeeze.",
        "Photo1Id": "Sawyer Micro.jpg",
        "Photo2Id": ""
    }, {
        "ItemId": 35,
        "CategoryId": 2,
        "ItemType": "Dirty water container",
        "ItemOrder": 35,
        "Description": "CNOC Vecto 2L",
        "Url": "https://cnocoutdoors.com/products/vecto-2l-28mm?variant=20550036357209",
        "IsWornClothing": false,
        "IsConsumable": false,
        "IsArchived": false,
        "Weight": 2.6,
        "Quantity": 1,
        "Notes": "The CNOC bag screws right on to the Sawyer Squeeze and the other end completely opens up to be a water scoop for quick fills, but " +
            "also for those more difficult water trickles.  The bag is soft, so it rolls up nice and tight, taking little room when empty.",
        "Photo1Id": "orange2Lpouch_filled_front_900x.jpg",
        "Photo2Id": ""
    }, {
        "ItemId": 36,
        "CategoryId": 2,
        "ItemType": "Clean water container",
        "ItemOrder": 36,
        "Description": "Smart water bottle",
        "Url": "https://www.drinksmartwater.com/",
        "IsWornClothing": false,
        "IsConsumable": false,
        "IsArchived": false,
        "Weight": 1.35,
        "Quantity": 2,
        "Notes": "Easily available anywhere along the trail, the Sawyer backflush adapter fits them, durable, slim and cheap.",
        "Photo1Id": "sw_base_mobile.jpg",
        "Photo2Id": ""
    }, {
        "ItemId": 37,
        "CategoryId": 3,
        "ItemType": "Rain Jacket",
        "ItemOrder": 37,
        "Description": "Montbell Versalite",
        "Url": "https://www.montbell.us/products/disp.php?p_id=2328169",
        "IsWornClothing": false,
        "IsConsumable": false,
        "IsArchived": false,
        "Weight": 7.6,
        "Quantity": 1,
        "Notes": "After the monsoon on CA Section A, I had to find a better rain solution.  I was soaked to the bone.  Stopping " +
            "in Julian the outfitters there recommended the Versalite.  It is well made, tough, pit zips, high and deep front " +
            "front pockets, hood and many draw strings to really clamp things down.  I feel invincible!  (at 7.6 ounces!)",
        "Photo1Id": "z_2328169_spbl.jpg",
        "Photo2Id": ""
    }, {
        "ItemId": 38,
        "CategoryId": 3,
        "ItemType": "Rain Jacket",
        "ItemOrder": 38,
        "Description": "Outdoor Research Helium II",
        "Url": "https://www.outdoorresearch.com/us/mens-helium-ii-jacket-242969?cat=234,224",
        "IsWornClothing": false,
        "IsConsumable": false,
        "IsArchived": true,
        "Weight": 6.4,
        "Quantity": 1,
        "Notes": "A lightweight jacket, perfect for most weather conditions, but just did not hold up against driving rain in 35mph winds.",
        "Photo1Id": "2429691344_4.jpg",
        "Photo2Id": ""
    }, {
        "ItemId": 39,
        "CategoryId": 3,
        "ItemType": "Rain Jacket",
        "ItemOrder": 39,
        "Description": "Mountain Hardware Ghost Whisperer Jacket",
        "Url": "https://www.sierra.com/mountain-hardwear-ghost-whisperer-hooded-jacket-super-ultralight-for-women~p~7991j/?redirectevent=review",
        "IsWornClothing": false,
        "IsConsumable": false,
        "IsArchived": true,
        "Weight": 3.4,
        "Quantity": 1,
        "Notes": "In my excitement to get a pack under ten pounds and having a great experience with my Ghost Whisperer puffy, I went " +
            "down the path of trying the Ghost Whisperer shell.  Mistake.  Super, light, yes.  Handle the tough weather - no.  It's an " +
            "amazing jacket for fall runs or in the fog/mist, but not for a thru-hike.",
        "Photo1Id": "P10900301-300x225.jpg",
        "Photo2Id": ""
    }, {
        "ItemId": 40,
        "CategoryId": 3,
        "ItemType": "Rain Pants",
        "ItemOrder": 40,
        "Description": "Zpacks Vertice Rain Pants (Large, tall)",
        "Url": "https://zpacks.com/products/vertice-rain-pants",
        "IsWornClothing": false,
        "IsConsumable": false,
        "IsArchived": false,
        "Weight": 3.25,
        "Quantity": 1,
        "Notes": "Totally waterproof, with a waist drawstring for fit.  There are no pockets, so you'll be using your jacket for any maps, etc.  " +
            "Overall I've been very happy with these and expect to use them for years to come.",
        "Photo1Id": "zpacks-vertice-rain-pants-front_2048x.jpg",
        "Photo2Id": ""
    }, {
        "ItemId": 41,
        "CategoryId": 3,
        "ItemType": "Down Jacket",
        "ItemOrder": 41,
        "Description": "Mountain Hardwear Ghost Whisperer Down Jacket (XL)",
        "Url": "https://www.mountainhardwear.com/p/mens-ghost-whisperer%2F2-jacket-1871621.html?dwvar_1871621_color=004&pos=8",
        "IsWornClothing": false,
        "IsConsumable": false,
        "IsArchived": false,
        "Weight": 8.5,
        "Quantity": 1,
        "Notes": "Love this puffy!  Warm, light, comfy and fits right.  Personally I choose to go without a hoodie and wear a down beanie hat.  The " +
            "Ghost Whisperer has established itself as one of the top down jackets out there.  My only question is do I get the UL version that's " +
            "new in 2020 at 6.6 ounces, or wait for them to come out with a non-hoody version?  Hrm....",
        "Photo1Id": "1871621_004_f.jpg",
        "Photo2Id": ""
    }, {
        "ItemId": 42,
        "CategoryId": 3,
        "ItemType": "Down Jacket",
        "ItemOrder": 42,
        "Description": "Patagonia Ultralight Down Hoody (XL)",
        "Url": "https://www.outdoorgearlab.com/reviews/clothing-mens/down-jacket/patagonia-ultralight-down-hoody",
        "IsWornClothing": false,
        "IsConsumable": false,
        "IsArchived": true,
        "Weight": 10.8,
        "Quantity": 1,
        "Notes": "My first ultralight down jacket and I was sold.  So warm, so crazy light, I just couldn't believe such a thing was even " +
            "possible.  In the end I found I prefered to not have a hoody and wear a separate down hat, so I save a few ounces in a non-hoody jacket.",
        "Photo1Id": "228914_25917_XL.jpg",
        "Photo2Id": ""
    }, {
        "ItemId": 43,
        "CategoryId": 3,
        "ItemType": "Warm Hat",
        "ItemOrder": 43,
        "Description": "BlackRock Down Hat",
        "Url": "https://www.blackrockgear.com/hats",
        "IsWornClothing": false,
        "IsConsumable": false,
        "IsArchived": false,
        "Weight": 1.0,
        "Quantity": 1,
        "Notes": "Handmade in a small location in the Seattle area.  Wonderfully warm, light, great for wearing around camp, in the morning, " +
            "or sleeping on cold nights.  Highly recommend this.",
        "Photo1Id": "3e770d_3223a7e3a08f4fac8d5405528e4bd90b_mv2_d_2448_2448_s_4_2.jpg",
        "Photo2Id": ""
    }, {
        "ItemId": 44,
        "CategoryId": 3,
        "ItemType": "Warm Gloves",
        "ItemOrder": 44,
        "Description": "DuraGlove ET Charcoal Wool",
        "Url": "https://defeet.com/products/duraglove-et-charcoal-wool-w-black-grippies",
        "IsWornClothing": false,
        "IsConsumable": false,
        "IsArchived": false,
        "Weight": 2.55,
        "Quantity": 1,
        "Notes": "First, you definitely want wool gloves because you will be wearing these while hiking, in the rain, etc, so you want something " +
            "that keeps you warm when wet.  These gloves allow me to use touch devices while wearing them, so that's nice.  Plus they have " +
            "little grippies on the palms and fingers.",
        "Photo1Id": "GLVETWCH_large.jpg",
        "Photo2Id": ""
    }, {
        "ItemId": 45,
        "CategoryId": 3,
        "ItemType": "Stuff Sack",
        "ItemOrder": 45,
        "Description": "Hyperlight DCF11 Stuff Sack (small)",
        "Url": "https://www.hyperlitemountaingear.com/products/drawstring-stuff-sacks",
        "IsWornClothing": false,
        "IsConsumable": false,
        "IsArchived": false,
        "Weight": 0.35,
        "Quantity": 2,
        "Notes": "I have two stuff sacks for my packed clothing.  The first sack is all my warm weather and rain gear, as I'll often have to pull " +
            "the warm weather stuff if it's raining :)  The second stuff sack contains all my sleepwear, sleeping pad, extra socks and underwear.",
        "Photo1Id": "hyperlite-mountain-gear-drawstring-stuff-sacks-small-gray-dcf11-2357357707309_800x1200.jpg",
        "Photo2Id": ""
    }, {
        "ItemId": 46,
        "CategoryId": 3,
        "ItemType": "Sleeping Socks",
        "ItemOrder": 46,
        "Description": "Darn Tough Hiker Crew Sockes (medium)",
        "Url": "https://darntough.com/collections/mens-hike-trek/products/micro-crew-cushion",
        "IsWornClothing": false,
        "IsConsumable": false,
        "IsArchived": false,
        "Weight": 2.6,
        "Quantity": 1,
        "Notes": "In general I don't sleep in socks, but when it's cold outside - you bet.  I want a pair of clean, dry, socks for sleeping " +
            "this helps keep dirt and stuff from my sleep system.  As an added plus, these can be used as my hiking socks if needed.",
        "Photo1Id": "1466_Denim_900x.jpg",
        "Photo2Id": ""
    }, {
        "ItemId": 47,
        "CategoryId": 3,
        "ItemType": "Sleeping Underwear",
        "ItemOrder": 47,
        "Description": "Exofficio Give-N-Go Boxer Brief",
        "Url": "https://www.exofficio.com/men/underwear/boxer-briefs/",
        "IsWornClothing": false,
        "IsConsumable": false,
        "IsArchived": false,
        "Weight": 2.75,
        "Quantity": 1,
        "Notes": "Just like with socks, I want clean underwear for when I sleep.  Sleeping in week old underwear sucks, makes the sleep " +
            "system stinky, etc.  Yes, I'm a boxer man.",
        "Photo1Id": "BoxerShorts.jpg",
        "Photo2Id": ""
    }, {
        "ItemId": 48,
        "CategoryId": 3,
        "ItemType": "Sleep Long Underwear Bottom",
        "ItemOrder": 48,
        "Description": "Patagonia Capilene Lightweight Long Underwear",
        "Url": "https://www.patagonia.com/product/mens-capilene-thermal-weight-bottoms/43687.html?dwvar_43687_color=FGX&cgid=root",
        "IsWornClothing": false,
        "IsConsumable": false,
        "IsArchived": false,
        "Weight": 4.0,
        "Quantity": 1,
        "Notes": "It looks like Patagonia stopped the 'Lightweight' line and the closest I can find now is the 'Thermal' line, so that's the link I've provided.  Anyway, " +
            "I really find that the Capilene's keep me warm at night (and remember that the industry standard for measuring ratings of sleeping bags/quilts is to assume you " +
            "are wearing long underwear).  Lightweight, warm and clean each night is a great way to recooperate at night :)",
        "Photo1Id": "884925.jpg",
        "Photo2Id": ""
    }, {
        "ItemId": 49,
        "CategoryId": 3,
        "ItemType": "Sleep Long Underwear Top",
        "ItemOrder": 49,
        "Description": "Patagonia Capilene Lightweight Long Underwear",
        "Url": "https://www.patagonia.com/product/mens-capilene-thermal-weight-crew-neck-shirt/43647.html?dwvar_43647_color=CRBX&cgid=mens-baselayers-thermal-weight",
        "IsWornClothing": false,
        "IsConsumable": false,
        "IsArchived": false,
        "Weight": 4.25,
        "Quantity": 1,
        "Notes": "Just like the bottoms, I cannot find the 'Lightweight' line anymore, so linked to the 'Thermal' line.  Love these.  Definitely worth carrying " +
            "clothes just for sleeping.",
        "Photo1Id": "45b750a2-3f2f-46f7-84d1-ff1a657a2c2e.jpg",
        "Photo2Id": ""
    }, {
        "ItemId": 50,
        "CategoryId": 3,
        "ItemType": "Camp shoes",
        "ItemOrder": 50,
        "Description": "Teva Voya Slide",
        "Url": "https://www.teva.com/men-sandals/voya-slide/1099272.html?dwvar_1099272_color=BTOL#q=slip-on&start=15&cgid=",
        "IsWornClothing": false,
        "IsConsumable": false,
        "IsArchived": false,
        "Weight": 10.8,
        "Quantity": 1,
        "Notes": "Camp shoes are a luxury item, and worth it for me.  It's nice to be able to get out of my wet shoes, wet socks, slide into my dry sleeping socks and " +
            "step about camp.  In town I'll wash my shoes, set them out to dry, and tromp around town in my camp shoes.  It's good for my feet to change it up a bit.  " +
            "I do not like flip-flops, or anything that has that bit between the toes.  It means my socks get bunched up in there, so I go with pure slip-ons and have " +
            "been happy.  Many people will gawk at the weight for this comfort, but it's a luxury I'll pay for with the everyday comfort :)",
        "Photo1Id": "1099272-BTOL_1.jpg",
        "Photo2Id": ""
    }, {
        "ItemId": 51,
        "CategoryId": 4,
        "ItemType": "Shirt",
        "ItemOrder": 51,
        "Description": "Icebreaker Merino wool long sleeve shirt",
        "Url": "https://www.icebreaker.com/en-us/mens-tops/merino-tech-lite-long-sleeve-crewe-t-shirt-oneberg/105162.html?dwvar_105162_color=401",
        "IsWornClothing": true,
        "IsConsumable": false,
        "IsArchived": false,
        "Weight": 8,
        "Quantity": 1,
        "Notes": "I often get asked both 'why wool' and 'why long-sleeve'.  Well, first, I chose Merino wool because it stays warm when wet, but more importantly " +
            "it resists getting stinky (and I'm a big sweat person).  I can easily wash it in a creek and cut back the amount of stink.  I chose long sleeve because " +
            "they cover my arms from the sun, I don't have to to wear multiple layers as often, and I can always pull up the sleeves if necessary.",
        "Photo1Id": "105162401_1.jpg",
        "Photo2Id": ""
    }, {
        "ItemId": 52,
        "CategoryId": 4,
        "ItemType": "Shorts",
        "ItemOrder": 52,
        "Description": "Nike Challenger running shorts",
        "Url": "https://www.nike.com/t/challenger-mens-7-2-in-1-running-shorts-GtwtTn/AJ7741-451",
        "IsWornClothing": true,
        "IsConsumable": false,
        "IsArchived": false,
        "Weight": 5.38,
        "Quantity": 1,
        "Notes": "Light, cool, breathes well, comfortable and with pockets.  Everything I need in shorts :)",
        "Photo1Id": "challenger-mens-7-2-in-1-running-shorts-GtwtTn.jpg",
        "Photo2Id": ""
    }, {
        "ItemId": 53,
        "CategoryId": 4,
        "ItemType": "Long underwear",
        "ItemOrder": 53,
        "Description": "Icebreaker Merino wool 200 leggings",
        "Url": "https://www.icebreaker.com/en-us/mens-baselayers/merino-200-oasis-thermal-leggings/104369.html?dwvar_104369_color=401",
        "IsWornClothing": true,
        "IsConsumable": false,
        "IsArchived": false,
        "Weight": 7.2,
        "Quantity": 1,
        "Notes": "I wear long leggings to protect from the sun, nettles and other ground scratchy things.  I like leggings over long " +
            "pants because they wick the sweat away better, keeping me cooler, and snag less often.  Plus, with Merino wool these keep " +
            "me warm when needed and resist sweat stink.",
        "Photo1Id": "104369401_1.jpg",
        "Photo2Id": ""
    }, {
        "ItemId": 54,
        "CategoryId": 4,
        "ItemType": "Underwear",
        "ItemOrder": 54,
        "Description": "Icebreaker Merino wool boxers",
        "Url": "https://www.icebreaker.com/en-us/mens-underwear/merino-175-everyday-thermal-boxers-with-fly/104485.html?dwvar_104485_color=001",
        "IsWornClothing": true,
        "IsConsumable": false,
        "IsArchived": false,
        "Weight": 4.1,
        "Quantity": 1,
        "Notes": "Keeping with the theme - Merino wool for warmth, comfort and stink resistance!",
        "Photo1Id": "104485001_1.jpg",
        "Photo2Id": ""
    }, {
        "ItemId": 55,
        "CategoryId": 4,
        "ItemType": "Socks",
        "ItemOrder": 55,
        "Description": "Darn Tough Men's Hiking Crew Socks",
        "Url": "https://darntough.com/products/micro-crew-cushion?variant=854540746771",
        "IsWornClothing": true,
        "IsConsumable": false,
        "IsArchived": false,
        "Weight": 2.6,
        "Quantity": 1,
        "Notes": "These socks are indestructable.  They are comfy, will keep your feet warm when wet, don't chafe and really all you need.  Get them.",
        "Photo1Id": "1466_Denim_900x (1).jpg",
        "Photo2Id": ""
    }, {
        "ItemId": 56,
        "CategoryId": 4,
        "ItemType": "Shoes",
        "ItemOrder": 56,
        "Description": "Altra Lone Peak 4",
        "Url": "https://www.altrarunning.com/shop/mens-shoes#facet=ads_f78004_ntk_cs%253A%2522Trail%2522&beginIndex=0",
        "IsWornClothing": true,
        "IsConsumable": false,
        "IsArchived": false,
        "Weight": 11.0,
        "Quantity": 1,
        "Notes": "I went thru quite a few set of shoes before finding these.  I find I love the large toe boxes on the Altra shoes, the rock plate " +
            "is key for long-term hiking, but somehow still super light and comfortable.  Shoes were the most critical part for me to figure out " +
            "and there was a lot of trial and error.",
        "Photo1Id": "altra-lone-peak-40.jpg",
        "Photo2Id": ""
    }, {
        "ItemId": 57,
        "CategoryId": 4,
        "ItemType": "Shoes",
        "ItemOrder": 57,
        "Description": "Altra Lone Peak RSM",
        "Url": "https://www.altrarunning.com/shop/mens-shoes-trail/lone-peak-4-low-rsm-alm1855l",
        "IsWornClothing": true,
        "IsConsumable": false,
        "IsArchived": true,
        "Weight": 11.0,
        "Quantity": 1,
        "Notes": "I tried these because of the RSM (Rain, Snow, Mud) aspect.  Waterproof sounded good.  Turns out that I didn't like it.  First, " +
            "my feet couldn't breathe per the normal trail runners with mesh, etc.  So my feet were more sweaty all day.  Second, the waterproofing is " +
            "really only useful for extremely shallow rain/snow/mud, as these are trail runners with low-tops.  Thus the waterproof aspect didn't often " +
            "come into play as a benefit.",
        "Photo1Id": "download (3).jpg",
        "Photo2Id": ""
    }, {
        "ItemId": 58,
        "CategoryId": 4,
        "ItemType": "Shoes",
        "ItemOrder": 58,
        "Description": "La Sportive Kaptiva",
        "Url": "https://www.sportiva.com/kaptiva.html",
        "IsWornClothing": true,
        "IsConsumable": false,
        "IsArchived": true,
        "Weight": 9.0,
        "Quantity": 1,
        "Notes": "I definitely got these a full size too small.  I wore them on a section hike in Washington and by the end of the first day my feet " +
            "were a little sore.  With no choice, I pushed on thru, but the downhills completely sucked because my toes kept hitting the front " +
            "of the shoe.  By then end of four days, I had four bruised toes and lost the toenails a few weeks later.  I don't know how I would " +
            "feel about these shoes if I'd oversized them by one full size, but I decided to try a different brand because of this experience.",
        "Photo1Id": "71SOOnAZSEL._AC_SR700,525_.jpg",
        "Photo2Id": ""
    }, {
        "ItemId": 59,
        "CategoryId": 4,
        "ItemType": "Trekking Poles",
        "ItemOrder": 59,
        "Description": "Gossamer Gear LT4 Trekking Poles",
        "Url": "https://www.gossamergear.com/collections/trekking-poles",
        "IsWornClothing": true,
        "IsConsumable": false,
        "IsArchived": false,
        "Weight": 4.6,
        "Quantity": 2,
        "Notes": "It is always a bummer when I find the perfect piece of gear, but then they stop making them :(  I'll use these poles until " +
            "they die.  Anyway, they are lightweight, strong, store easily, comfortable and I rely on them all day (downhills, balance, stream " +
            "crossings, etc.).",
        "Photo1Id": "tempImageForSave-10.jpg",
        "Photo2Id": ""
    }, {
        "ItemId": 60,
        "CategoryId": 4,
        "ItemType": "Sun hat",
        "ItemOrder": 60,
        "Description": "Seattle Kraken baseball cap",
        "Url": "https://shop.nhl.com/seattle-kraken/mens-seattle-kraken-fanatics-branded-white-core-primary-logo-adjustable-hat/t-43218066+p-3766015568093+z-9-3850850320?_ref=p-DLP:m-GRID:i-r5c2:po-17",
        "IsWornClothing": true,
        "IsConsumable": false,
        "IsArchived": false,
        "Weight": 3.1,
        "Quantity": 1,
        "Notes": "I love hockey, Seattle is getting an NHL team, so this was the logical choice.  I find baseball caps more comfortable than the floppy hats I often see hikers wearing.",
        "Photo1Id": "KrakenHat.jpg",
        "Photo2Id": ""
    }, {
        "ItemId": 61,
        "CategoryId": 4,
        "ItemType": "Sun hat",
        "ItemOrder": 61,
        "Description": "Outdoor Research Helios sun hat",
        "Url": "https://www.moosejaw.com/product/outdoor-research-helios-sun-hat_10006942",
        "IsWornClothing": true,
        "IsConsumable": false,
        "IsArchived": true,
        "Weight": 2.3,
        "Quantity": 1,
        "Notes": "I've worn floppy hats all my life when at the beach, on the boat, etc, so I went this way at first.  What I found was that it didn't really work for me in backpacking.  " +
            "The back rim rubs against the pack, I can't wear the hat under the hood of my rain jacket like I can with a baseball cap, so in the end I ditched " +
            "floppy hat idea.",
        "Photo1Id": "10006942x1013712_zm.jpg",
        "Photo2Id": ""
    }, {
        "ItemId": 62,
        "CategoryId": 5,
        "ItemType": "Trowel",
        "ItemOrder": 62,
        "Description": "Deuce of spades",
        "Url": "http://www.thetentlab.com/Deuce/DeuceofSpadespage.html",
        "IsWornClothing": false,
        "IsConsumable": false,
        "IsArchived": false,
        "Weight": 0.5,
        "Quantity": 1,
        "Notes": "Who knew I'd spend time in my life reviewing a cathole trowel, but there you go.  The Deuce is the best in my mind.  Light as heck, solid, will " +
            "dig thru anything.",
        "Photo1Id": "deuce-in-use-576.jpg",
        "Photo2Id": ""
    }, {
        "ItemId": 63,
        "CategoryId": 5,
        "ItemType": "Toilet paper",
        "ItemOrder": 63,
        "Description": "Charmin TP",
        "Url": "https://www.charmin.com/en-us/shop-products/toilet-paper/ultra-soft-super-mega-roll",
        "IsWornClothing": false,
        "IsConsumable": false,
        "IsArchived": false,
        "Weight": 2.5,
        "Quantity": 1,
        "Notes": "Yeah, I know many people will blast at carrying TP - 'use leaves' they say.  Bah, says I.  I like the comfort and softness, so I carry it.  So be it.",
        "Photo1Id": "CHM_UltraSoft-Mega_Molly.jpg",
        "Photo2Id": ""
    }, {
        "ItemId": 64,
        "CategoryId": 5,
        "ItemType": "Toothbrush",
        "ItemOrder": 64,
        "Description": "Simple plastic toothbrush from my dentist",
        "Url": "https://www.tomsofmaine.com/products/oral-care/naturally-clean-toothbrush/soft",
        "IsWornClothing": false,
        "IsConsumable": false,
        "IsArchived": false,
        "Weight": 0.25,
        "Quantity": 1,
        "Notes": "Those crazy ultra-light people will tell me to cut the toothbrush in half, saving .1 ounces.  Well, this is one of those times where I think 'Hey, have " +
            "to do this twice a day, for six months in a row.  I don't want to brush my teeth with just my finger tips holding on.'  I'll pay the extra .1 ounces for full " +
            "handed, comfortable brushing.",
        "Photo1Id": "toms-of-maine-naturally-clean-toothbrush-soft.jpg",
        "Photo2Id": ""
    }, {
        "ItemId": 65,
        "CategoryId": 5,
        "ItemType": "Toothpaste",
        "ItemOrder": 65,
        "Description": "Sensodyne toothpaste (dehydrated drops)",
        "Url": "https://www.sensodyne.com/en-us/products/sensodyne-deep-clean-toothpaste/",
        "IsWornClothing": false,
        "IsConsumable": false,
        "IsArchived": false,
        "Weight": 0.4,
        "Quantity": 1,
        "Notes": "Toothpaste is one place where I do some 'ultra-lighting'.  I don't carry a full tube, or even a travel size, I made toothpaste drops (lots of them) " +
            "and I carry a bunch of the drops in a plastic baggie.  Saved a few ounces of weight this way.",
        "Photo1Id": "Product-Essential-Care-deep-clean.jpg",
        "Photo2Id": ""
    }, {
        "ItemId": 66,
        "CategoryId": 5,
        "ItemType": "Dental Floss",
        "ItemOrder": 66,
        "Description": "Glide travel size",
        "Url": "https://oralb.com/en-us/products/compare/floss",
        "IsWornClothing": false,
        "IsConsumable": false,
        "IsArchived": false,
        "Weight": 0.5,
        "Quantity": 1,
        "Notes": "Got to keep those teeth clean, including between them!",
        "Photo1Id": "glide floss.jpg",
        "Photo2Id": ""
    }, {
        "ItemId": 67,
        "CategoryId": 5,
        "ItemType": "Soap",
        "ItemOrder": 67,
        "Description": "Dr. Bronner's Soap",
        "Url": "https://shop.drbronner.com/collections/all/products/sal-suds-biodegradable-cleaner",
        "IsWornClothing": false,
        "IsConsumable": false,
        "IsArchived": false,
        "Weight": 0.75,
        "Quantity": 1,
        "Notes": "I use the soap for washing myself, clothes or my pack towel in a stream or lake.  Really it's to keep the stink down a bit.  " +
            "I put the soap in a small squeeze bottle, don't want to carry a full ton of soap :).",
        "Photo1Id": "Image_7_340x.jpg",
        "Photo2Id": ""
    }, {
        "ItemId": 68,
        "CategoryId": 5,
        "ItemType": "Travel Squeeze Bottle",
        "ItemOrder": 68,
        "Description": "OTO Travel Squeeze Bottle (1.7 fl oz)",
        "Url": "https://www.amazon.com/OTO-Travel-Squeeze-Bottle-Labels/dp/B06XGGPD2P",
        "IsWornClothing": false,
        "IsConsumable": false,
        "IsArchived": false,
        "Weight": 0.5,
        "Quantity": 1,
        "Notes": "I put soap and other liquids in these bottles, keeping the amount of liquid I need to cover me for a few weeks.  This really cuts " +
            "down the overall weight.  Love these guys.",
        "Photo1Id": "TravelSqueezeBottle.jpg",
        "Photo2Id": ""
    }, {
        "ItemId": 69,
        "CategoryId": 5,
        "ItemType": "Sanitizer",
        "ItemOrder": 69,
        "Description": "Purell Travel Size",
        "Url": "https://www.gojo.com/en/Product/9650-24-CMR",
        "IsWornClothing": false,
        "IsConsumable": false,
        "IsArchived": false,
        "Weight": 1.3,
        "Quantity": 1,
        "Notes": "I carry hand sanitizer as well as soap, as this is much more convenient for the daily hand cleaning before dinner.",
        "Photo1Id": "bclzarhmf9epz9jkkqcl.jpg",
        "Photo2Id": ""
    }, {
        "ItemId": 70,
        "CategoryId": 5,
        "ItemType": "Hand Towel",
        "ItemOrder": 70,
        "Description": "Packtowl Nano towel",
        "Url": "https://www.packtowl.com/original/original.html",
        "IsWornClothing": false,
        "IsConsumable": false,
        "IsArchived": false,
        "Weight": 1.15,
        "Quantity": 1,
        "Notes": "I love this towel.  I use it all the time.  Cleaning out my cook pot, my sporc, wiping down the inside of the tent, ... on and on.  " +
            "It dries quickly, sops up a ton, and is rugged.  I consider this an essential for managing my sanity on keeping things dry and clean.",
        "Photo1Id": "NanoTowel.jpg",
        "Photo2Id": ""
    }, {
        "ItemId": 71,
        "CategoryId": 5,
        "ItemType": "Body Glide",
        "ItemOrder": 71,
        "Description": "Body Glide",
        "Url": "https://www.bodyglide.com/product/body/",
        "IsWornClothing": false,
        "IsConsumable": false,
        "IsArchived": false,
        "Weight": 1.75,
        "Quantity": 1,
        "Notes": "I am one of the unfortunates that suffer from groin chaffing, so Body Glide is a requirement for me.  Without it I suffer within " +
            "within the first day or so from chaffing pain.  Every step hurts.  With it - no problems at all.",
        "Photo1Id": "body-1-50-oz-660x1123.jpg",
        "Photo2Id": ""
    }, {
        "ItemId": 72,
        "CategoryId": 5,
        "ItemType": "Ibuprofen",
        "ItemOrder": 72,
        "Description": "2 weeks of daily ibuprofen",
        "Url": "https://www.costco.com/kirkland-signature-ibuprofen-200-mg.%2c-1%2c000-tablets.product.11489095.html",
        "IsWornClothing": false,
        "IsConsumable": false,
        "IsArchived": false,
        "Weight": 0.4,
        "Quantity": 1,
        "Notes": "An admission of age, needing to carry ibuprofen for every day.  I take one in the morning and one at night.  I put a couple of weeks " +
            "worth in a simply ziplock baggie and life is better.",
        "Photo1Id": "imageService.jpg",
        "Photo2Id": ""
    }, {
        "ItemId": 73,
        "CategoryId": 5,
        "ItemType": "Ziplock bag",
        "ItemOrder": 73,
        "Description": "1 Gallon Ziplock bag for all this stuff",
        "Url": "https://ziploc.com/en/products/bags/slider/slider-storage-bags-gallon-large",
        "IsWornClothing": false,
        "IsConsumable": false,
        "IsArchived": false,
        "Weight": 0.15,
        "Quantity": 1,
        "Notes": "I like to bundle all my hygiene stuff into a single clear bag, with a solid zipper.  Keeps like-minded things together for easy access.",
        "Photo1Id": "jut1pvspkfrch5xbdvjk.jpg",
        "Photo2Id": ""
    }, {
        "ItemId": 74,
        "CategoryId": 6,
        "ItemType": "Medical Kit",
        "ItemOrder": 74,
        "Description": "Adventure Medical Kit .7 (stripped down)",
        "Url": "https://www.adventuremedicalkits.com/medical-kits/ultralight-watertight/ultralight-watertight-7.html",
        "IsWornClothing": false,
        "IsConsumable": false,
        "IsArchived": false,
        "Weight": 3.3,
        "Quantity": 1,
        "Notes": "I've stripped down the med kit to a bunch of bandages, tweezers, tape, gauze, tylenol, mole skin and such.",
        "Photo1Id": "3ZmydV1q.jpg",
        "Photo2Id": ""
    }, {
        "ItemId": 75,
        "CategoryId": 6,
        "ItemType": "Bug Repellent",
        "ItemOrder": 75,
        "Description": "Ben's Spray 30 DEET",
        "Url": "https://bens30.com/deet/30-deet/bens-30-3-4oz-pump.html",
        "IsWornClothing": false,
        "IsConsumable": false,
        "IsArchived": false,
        "Weight": 0.7,
        "Quantity": 1,
        "Notes": "I hate mosquitos, the little bloodsuckers.  I don't particularly like the smell of DEET either, but it's the lesser of two evils.",
        "Photo1Id": "wgJE1SYK.jpg",
        "Photo2Id": ""
    }, {
        "ItemId": 76,
        "CategoryId": 6,
        "ItemType": "Lip Balm",
        "ItemOrder": 76,
        "Description": "Bert's Bees",
        "Url": "https://www.burtsbees.com/lip-care/lip-balm/",
        "IsWornClothing": false,
        "IsConsumable": false,
        "IsArchived": false,
        "Weight": 0.3,
        "Quantity": 1,
        "Notes": "Dry lips are a fact in the desert and high country.  Take care of your lips, or end up with cracks and blood.",
        "Photo1Id": "792850905535_1.jpg",
        "Photo2Id": ""
    }, {
        "ItemId": 77,
        "CategoryId": 6,
        "ItemType": "Sunscreen",
        "ItemOrder": 77,
        "Description": "Gel SPF50 Sunscreen",
        "Url": "https://www.amazon.com/Heliocare-SPF-50-Gel-Ml/dp/B002KDTGVE",
        "IsWornClothing": false,
        "IsConsumable": false,
        "IsArchived": false,
        "Weight": 1.0,
        "Quantity": 1,
        "Notes": "Sunscreen is a must.  I prefer gel sunscreen when backpacking, it seems to resist sweating better, or at least in my mind.",
        "Photo1Id": "OIP (1).jpg",
        "Photo2Id": ""
    }, {
        "ItemId": 78,
        "CategoryId": 6,
        "ItemType": "Travel Squeeze Bottle",
        "ItemOrder": 78,
        "Description": "OTO Travel Squeeze Bottle (1.7 fl oz)",
        "Url": "https://www.amazon.com/OTO-Travel-Squeeze-Bottle-Labels/dp/B06XGGPD2P",
        "IsWornClothing": false,
        "IsConsumable": false,
        "IsArchived": false,
        "Weight": 0.5,
        "Quantity": 1,
        "Notes": "I put my sunscreen in here, rather than lug around the full thing.",
        "Photo1Id": "TravelSqueezeBottle.jpg",
        "Photo2Id": ""
    }, {
        "ItemId": 79,
        "CategoryId": 6,
        "ItemType": "Massage Ball",
        "ItemOrder": 79 ,
        "Description": "Rawlogy Cork Massage Ball (medium)",
        "Url": "https://www.litesmith.com/cork-massage-balls/",
        "IsWornClothing": false,
        "IsConsumable": false,
        "IsArchived": false,
        "Weight": 1.5,
        "Quantity": 1,
        "Notes": "Stretching every evening and morning is a must for me.  Getting older sucks.  Anyway, as part of that, rolling out my " +
            "hamstrings, quads, calves and lower back is super beneficial.  This is yet another reason I love having a two-person tent, as I " +
            "can do all of it inside my shelter :)",
        "Photo1Id": "Massage-Ball-Single__27913.1602962035.1280.1280.jpg",
        "Photo2Id": ""
    }, {
        "ItemId": 90,
        "CategoryId": 7,
        "ItemType": "Emergency Beacon",
        "ItemOrder": 90,
        "Description": "Garmin InReach Mini",
        "Url": "https://buy.garmin.com/en-US/US/p/592606",
        "IsWornClothing": false,
        "IsConsumable": false,
        "IsArchived": false,
        "Weight": 3.5,
        "Quantity": 1,
        "Notes": "This little guy is critical.  Not only for safety, but my family and friends can see where I am at regular intervals.  " +
            "Plus, my wife can message me for updates, critical questions, and less critical questions.  There are many, many places on " +
            "the trails where one simply cannot get cel coverage for days at a time.  Hence, this guy.  The battery lasts about 7 days " +
            "based on my upload and messaging frequency.  Awesome.",
        "Photo1Id": "cf-lg-7723cfb6-c11b-4761-a41e-96dd0983e10e.jpg",
        "Photo2Id": ""
    }, {
        "ItemId": 91,
        "CategoryId": 7,
        "ItemType": "Emergency Beacon",
        "ItemOrder": 91,
        "Description": "Garmin InReach Explorer+",
        "Url": "https://buy.garmin.com/en-US/US/p/561269",
        "IsWornClothing": false,
        "IsConsumable": false,
        "IsArchived": true,
        "Weight": 7.5,
        "Quantity": 1,
        "Notes": "I bought the full-size InReach first.  My thinking was that it had a screen, so that if my phone died or " +
            "or worse, the InReach would be fully usable without the phone.  Great idea and still valid.  However, I realized " +
            "that in the event of my phone dying, I would only have to make it for just a couple of days to get to a point " +
            "where I could buy a new phone.  Meanwhile the Mini would still be able to send the pre-defined messages, so " +
            "there would be some communication possible, as well as the tracking functions would still work.  So, to save " +
            "weight I put this InReach aside.  It's a good device, would recommend it, I just made a hard choice.",
        "Photo1Id": "cf-lg.jpg",
        "Photo2Id": ""
    }, {
        "ItemId": 92,
        "CategoryId": 7,
        "ItemType": "Phone",
        "ItemOrder": 92,
        "Description": "Google Pixel 2",
        "Url": "https://www.gsmarena.com/google_pixel_2-8733.php",
        "IsWornClothing": false,
        "IsConsumable": false,
        "IsArchived": false,
        "Weight": 5.2,
        "Quantity": 1,
        "Notes": "Full functioning phone and low weight.  The battery lasts a couple of days in 'airplane mode', so I can " +
            "use it for listening to books at night, using Guthooks app, etc.",
        "Photo1Id": "google-pixel-2-1.jpg",
        "Photo2Id": ""
    }, {
        "ItemId": 93,
        "CategoryId": 7,
        "ItemType": "Phone",
        "ItemOrder": 93,
        "Description": "Jelly Pro",
        "Url": "https://www.amazon.com/gp/product/B07TS37SR1",
        "IsWornClothing": false,
        "IsConsumable": false,
        "IsArchived": true,
        "Weight": 2.2,
        "Quantity": 1,
        "Notes": "I briefly went down a rabbit hole of trying to cut weight in my phone option.  So I started with " +
            "the lightest phone out there, the Jelly Pro.  Coming in at a remarkable 2.2 ounces!!  Well, I found that " +
            "smaller is not always better.  Yes, I can send/receive calls, but messaging was tough on a tiny touch " +
            "keyboard, and apps just didn't display in a useful way.  So this sits in a box now.",
        "Photo1Id": "51zYkXaIQuL._AC_SL1001_.jpg",
        "Photo2Id": ""
    }, {
        "ItemId": 94,
        "CategoryId": 7,
        "ItemType": "Phone",
        "ItemOrder": 94,
        "Description": "BLU Advance L4",
        "Url": "https://www.amazon.com/gp/product/B07JDNVTFG",
        "IsWornClothing": false,
        "IsConsumable": false,
        "IsArchived": true,
        "Weight": 3.8,
        "Quantity": 1,
        "Notes": "Having gone too small with the Jelly, I tried the BLU Advance L4 phone.  It's between the Pixel 2 " +
            "and Jelly in size and weight.  Again, tho, I found the experience of using the phone to be too hard, the " +
            "screen just seemed a bit too small (still).  So, in the end I decided the 1.4 ounces for the full phone " +
            "was worth the weight.",
        "Photo1Id": "71HVRsd9hbL._AC_SL1335_.jpg",
        "Photo2Id": ""
    }, {
        "ItemId": 95,
        "CategoryId": 7,
        "ItemType": "Power bank",
        "ItemOrder": 95,
        "Description": "Anker PowerCore II 10000",
        "Url": "https://www.anker.com/products/variant/powercore-ii-10000/A1230011",
        "IsWornClothing": false,
        "IsConsumable": false,
        "IsArchived": false,
        "Weight": 6.8,
        "Quantity": 1,
        "Notes": "At first I tried section hiking without a power bank.  I found myself stressing about power management, " +
            "constantly checking my beacon and phone charge levels, calculating if they would last 5 days, etc.  For " +
            "sanity I decided to try a power bank and have never looked back.  In civilization I charge it up as much " +
            "as possible, then head on out.  Everything else I charge from it.  Now I can listen to music, books, even " +
            "download episodes to binge on for on the trail and not worry about running out of power.  I got the PowerCore " +
            "II because it has eight levels of power indication, instead of four on others, which gives me a better idea " +
            "of how much charge is left and whether I need to cut back on my luxury power consumptions - hehehe.",
        "Photo1Id": "A1230011_1123.jpg",
        "Photo2Id": ""
    }, {
        "ItemId": 96,
        "CategoryId": 7,
        "ItemType": "Power bank",
        "ItemOrder": 96,
        "Description": "Anker PowerCore 10000",
        "Url": "https://www.anker.com/products/variant/powercore-speed-10000/A1266011",
        "IsWornClothing": false,
        "IsConsumable": false,
        "IsArchived": true,
        "Weight": 7.2,
        "Quantity": 1,
        "Notes": "I used this guy for a while, but it only indicates four levels of charge, so I found myself stressing " +
            "on it's real charge level (40% or 60%?  Big difference!).  So I changed to the PowerCore II for it's eight " +
            "levels of indication.",
        "Photo1Id": "A1266011_TD01_V2.jpg",
        "Photo2Id": ""
    }, {
        "ItemId": 97,
        "CategoryId": 7,
        "ItemType": "Power bank",
        "ItemOrder": 97,
        "Description": "All-in-one High Capacity Power Bank",
        "Url": "https://www.amazon.com/gp/product/B087CSSXPR",
        "IsWornClothing": false,
        "IsConsumable": false,
        "IsArchived": true,
        "Weight": 11.2,
        "Quantity": 1,
        "Notes": "I experimented with something a little different.  This power bank has a built-in wall charger, several cables types, and 15000 mAh capacity.  " +
            "So I thought the overall weight might be similar, while getting 50% more capacity.  In addition, the charge indicator is a digital readout of " +
            "percent charged.  In the end this was 3 ounces more and I rarely, if ever, will need the extra capacity, so there is no reason to carry that around.",
        "Photo1Id": "AllInOnePowerBank.jpg",
        "Photo2Id": ""
    }, {
        "ItemId": 98,
        "CategoryId": 7,
        "ItemType": "Wall Charger",
        "ItemOrder": 98,
        "Description": "Anker Nano",
        "Url": "https://us.anker.com/pages/uai2020",
        "IsWornClothing": false,
        "IsConsumable": false,
        "IsArchived": false,
        "Weight": 1.1,
        "Quantity": 1,
        "Notes": "The Anker Nano is small, light, and outputs up to 20w of power for charging - improving the charging speed of my power bank by 3x versus " +
            "the oldest USB charger in my house.",
        "Photo1Id": "51gHyK0YQVL._AC_SL1500_.jpg",
        "Photo2Id": ""
    }, {
        "ItemId": 99,
        "CategoryId": 7,
        "ItemType": "Charging cables",
        "ItemOrder": 99,
        "Description": "Various short charging cables",
        "Url": "https://www.amazon.com/gp/product/B01LONQ7R6",
        "IsWornClothing": false,
        "IsConsumable": false,
        "IsArchived": false,
        "Weight": 0.8,
        "Quantity": 1,
        "Notes": "I use short cables to keep weight down, plus I just don't need long cables that will tangle up in my bag anyway.",
        "Photo1Id": "51atTyB6rFL._AC_SL1500_.jpg",
        "Photo2Id": ""
    }, {
        "ItemId": 100,
        "CategoryId": 7,
        "ItemType": "Watch",
        "ItemOrder": 100,
        "Description": "Casio F105W Men's watch",
        "Url": "https://www.casio.com/products/watches/classic/f105w-1a",
        "IsWornClothing": false,
        "IsConsumable": false,
        "IsArchived": false,
        "Weight": 0.75,
        "Quantity": 1,
        "Notes": "I don't wear a watch, but I like to have one hanging off my pack so I can do a time check now and then.  Also, " +
            "this gives me an alarm clock where I don't have to have my phone on all night.  The watch is waterproof, simple and cheap.",
        "Photo1Id": "F105W-1A_large.jpg",
        "Photo2Id": ""
    }, {
        "ItemId": 101,
        "CategoryId": 7,
        "ItemType": "Camera Mount",
        "ItemOrder": 101,
        "Description": "StickPic cell phone adapter",
        "Url": "https://www.thestickpic.com/",
        "IsWornClothing": false,
        "IsConsumable": false,
        "IsArchived": false,
        "Weight": 1.2,
        "Quantity": 1,
        "Notes": "As part of my adaptation the this new world of selfies, video, etc, I dediced to try out taking selfie-videos while " +
            "walking.  This is to share the experience with my friends and family in near real-time.  I found it very simple, easy and " +
            "was surprised that the image was so stable.  Now I carry this full time.",
        "Photo1Id": "s-l300 (1).jpg",
        "Photo2Id": ""
    }, {
        "ItemId": 102,
        "CategoryId": 7,
        "ItemType": "Headphones",
        "ItemOrder": 102,
        "Description": "Boltune IPX7 Waterproof",
        "Url": "https://www.boltune.com/products/bt-bh001-sports-headphones",
        "IsWornClothing": false,
        "IsConsumable": false,
        "IsArchived": false,
        "Weight": 0.63,
        "Quantity": 1,
        "Notes": "At this ridiculously small weight, all day power, why would I not carry headphones?!?  Wow.  This is awesome because I " +
            "get to listen to music on those rough days, audible books at night, or jam out when I'm in town.  I am so very happy " +
            "I decided to go down this path.  Last remark, get waterproof and with a microphone, so you can make hands-free calls " +
            "in any weather.",
        "Photo1Id": "boltunebt-bh001sportsheadphones_4_5000x.jpg",
        "Photo2Id": ""
    }, {
        "ItemId": 110,
        "CategoryId": 8,
        "ItemType": "Headlamp",
        "ItemOrder": 110,
        "Description": "Nitecore NU20",
        "Url": "https://www.nitecorestore.com/Nitecore-NU20-LED-Rechargeable-Headlamp-p/fl-nite-nu20.htm",
        "IsWornClothing": false,
        "IsConsumable": false,
        "IsArchived": false,
        "Weight": 1.85,
        "Quantity": 1,
        "Notes": "I like this headlamp for several reasons.  It is ridiculously light at 1.85 ounces, headband included!  It is rechargable, so I can " +
            "use my power bank and not have to carry extra batteries.  It is bright, with many levels of brightness available.",
        "Photo1Id": "NiteCore.jpg",
        "Photo2Id": ""
    }, {
        "ItemId": 111,
        "CategoryId": 8,
        "ItemType": "Headlamp",
        "ItemOrder": 111,
        "Description": "Petzl Actik Headlamp",
        "Url": "https://www.petzl.com/US/en/Sport/ACTIVE-headlamps/ACTIK",
        "IsWornClothing": false,
        "IsConsumable": false,
        "IsArchived": true,
        "Weight": 3.8,
        "Quantity": 1,
        "Notes": "This was my previous headlamp, but was a bit heavier and required batteries.  It is a great headlamp, so I'm not knocking it.",
        "Photo1Id": "ACTIK_LowRes.jpg",
        "Photo2Id": ""
    }, {
        "ItemId": 112,
        "CategoryId": 8,
        "ItemType": "Repair Tape",
        "ItemOrder": 112,
        "Description": "Tenacious Tape",
        "Url": "https://www.gearaid.com/collections/tenacious-tape",
        "IsWornClothing": false,
        "IsConsumable": false,
        "IsArchived": false,
        "Weight": 0.8,
        "Quantity": 1,
        "Notes": "Tenacious tape is the duct tape of the backpacking world.  Useful for repairing just about anything.  I carry enough for " +
            "emegency repairs to get me to the next town.",
        "Photo1Id": "10800-TenaciousTape-FlexPatches-Comp_1200x.jpg",
        "Photo2Id": ""
    }, {
        "ItemId": 113,
        "CategoryId": 8,
        "ItemType": "Maps",
        "ItemOrder": 113,
        "Description": "A couple of weeks worth of paper maps",
        "Url": "https://www.natgeomaps.com/trail-maps/trails-illustrated-maps/pacific-crest-trail",
        "IsWornClothing": false,
        "IsConsumable": false,
        "IsArchived": false,
        "Weight": 0.5,
        "Quantity": 1,
        "Notes": "I carry waterproof paper maps because it's safe.  If all electronics fail, I can still know where I am and where to get off trail.",
        "Photo1Id": "0-0-0.jpg",
        "Photo2Id": ""
    }, {
        "ItemId": 114,
        "CategoryId": 8,
        "ItemType": "Log Book",
        "ItemOrder": 114,
        "Description": "Paper for a couple weeks of writing memories",
        "Url": "https://www.walmart.com/search/?query=paper",
        "IsWornClothing": false,
        "IsConsumable": false,
        "IsArchived": false,
        "Weight": 0.8,
        "Quantity": 1,
        "Notes": "I write every night things about the trail, memories or stories that popped into my head, log information like distance, weather, etc.",
        "Photo1Id": "Notebook Paper 1.jpg",
        "Photo2Id": ""
    }, {
        "ItemId": 115,
        "CategoryId": 8,
        "ItemType": "Pencil",
        "ItemOrder": 115,
        "Description": "A Pencil",
        "Url": "https://www.walmart.com/search/?query=pencil",
        "IsWornClothing": false,
        "IsConsumable": false,
        "IsArchived": false,
        "Weight": 0.2,
        "Quantity": 1,
        "Notes": "I carry a pencil because I can sharpen it with my knife, it doesn't run out of ink, and works upside down and in space.",
        "Photo1Id": "Pencil.jpg",
        "Photo2Id": ""
    }, {
        "ItemId": 116,
        "CategoryId": 8,
        "ItemType": "Cord",
        "ItemOrder": 116,
        "Description": "25' of lighweight nylon cord",
        "Url": "https://zpacks.com/products/50-feet-2-0-mm-z-line-cord",
        "IsWornClothing": false,
        "IsConsumable": false,
        "IsArchived": false,
        "Weight": 1.6,
        "Quantity": 1,
        "Notes": "Cord is useful not only for hanging a food bag, but for drying clothes, reaching that tree with a tent stake line in the snow, etc.  " +
            "I always carry cord because I'll need it and then hate myself for over optimizing if I don't have it.",
        "Photo1Id": "zpacks-z-line-dyneema-cord-black_60b7c139-0362-4ce4-80e8-da2cc5894768_800x.jpg",
        "Photo2Id": ""
    }, {
        "ItemId": 117,
        "CategoryId": 8,
        "ItemType": "Wallet bag",
        "ItemOrder": 117,
        "Description": "Zip bag with my id, credit cards, cash and health insurance card",
        "Url": "https://www.ula-equipment.com/product/internal-stash-pocket/",
        "IsWornClothing": false,
        "IsConsumable": false,
        "IsArchived": false,
        "Weight": 1.6,
        "Quantity": 1,
        "Notes": "My stash bag is orange, with my trail name and phone number on it.  Having something that zips keeps the change in there, but " +
            "also means I don't drop or lose a vital card.",
        "Photo1Id": "InternalStashPocket.jpg",
        "Photo2Id": ""
    }, {
        "ItemId": 118,
        "CategoryId": 8,
        "ItemType": "Spare Precription Glasses",
        "ItemOrder": 118,
        "Description": "Spare prescription glasses in a soft bag",
        "Url": "https://www.youtube.com/watch?v=dQw4w9WgXcQ",
        "IsWornClothing": false,
        "IsConsumable": false,
        "IsArchived": false,
        "Weight": 1.05,
        "Quantity": 1,
        "Notes": "I am blind as a bat without glasses, so need a backup pair.",
        "Photo1Id": "450.jpg",
        "Photo2Id": ""
    }, {
        "ItemId": 119,
        "CategoryId": 8,
        "ItemType": "Tupperware for fragile items",
        "ItemOrder": 119,
        "Description": "Small tupperware container",
        "Url": "https://www.tupperware.com/snack-stor-large-container/",
        "IsWornClothing": false,
        "IsConsumable": false,
        "IsArchived": false,
        "Weight": 1.35,
        "Quantity": 1,
        "Notes": "I keep my glasses, wall charger, cords, headlamp, spare flint and other small items in this.  Works great and I love being organized about it :)",
        "Photo1Id": "snack-stor-large-0324.jpg",
        "Photo2Id": ""
    }, {
        "ItemId": 120,
        "CategoryId": 8,
        "ItemType": "Velcro ties",
        "ItemOrder": 120,
        "Description": "Small tupperware container",
        "Url": "https://www.walmart.com/ip/Reusable-Cable-Tie-6-Inches-Hook-and-Loop-Cord-Wrap-5-Color-Adjustable-Strap-10pcs/124210426",
        "IsWornClothing": false,
        "IsConsumable": false,
        "IsArchived": false,
        "Weight": 0.45,
        "Quantity": 1,
        "Notes": "I carry a bunch of velcro cable ties.  They are useful for a variety of things. I've strapped sticks together to form a long stick, or " +
            "attach more firmly the hiking sticks to the backpack, or just making stuff secure.",
        "Photo1Id": "Velcro.jpg",
        "Photo2Id": ""
    }, {
        "ItemId": 121,
        "CategoryId": 8,
        "ItemType": "Ziploc bag",
        "ItemOrder": 121,
        "Description": "Waterproofing for logs and maps",
        "Url": "https://ziploc.com/en/products/bags/slider/slider-storage-bags-gallon-large",
        "IsWornClothing": false,
        "IsConsumable": false,
        "IsArchived": false,
        "Weight": 0.15,
        "Quantity": 1,
        "Notes": "Need something to make sure my papers don't get wet - so bundle them all in a ziploc!",
        "Photo1Id": "jut1pvspkfrch5xbdvjk.jpg",
        "Photo2Id": ""
    }, {
        "ItemId": 122,
        "CategoryId": 8,
        "ItemType": "Umbrella",
        "ItemOrder": 122,
        "Description": "Snowpeak UL travel umbrella",
        "Url": "https://snowpeak.com/products/ultra-light-grey-umbrella",
        "IsWornClothing": false,
        "IsConsumable": false,
        "IsArchived": false,
        "Weight": 5.15,
        "Quantity": 1,
        "Notes": "I love my umbrella.  It straps right on to the shoulder harness of the backpack with a couple of those velcro ties, solid.  It " + 
            "doesn't work well in windy conditions, which is when I go full turtle with my rain gear and such, but for all other rains and harse " +
            "sun situations it protects me and my pack.",
        "Photo1Id": "Umbrella.jpg",
        "Photo2Id": ""
    }, {
        "ItemId": 130,
        "CategoryId": 9,
        "ItemType": "Water",
        "ItemOrder": 130,
        "Description": "Water - 1L on average",
        "Url": "https://en.wikipedia.org/wiki/Stream",
        "IsWornClothing": false,
        "IsConsumable": true,
        "IsArchived": false,
        "Weight": 36,
        "Quantity": 1,
        "Notes": "Gotta have water :)",
        "Photo1Id": "330px-Potok_pod_jezerom_1.jpg",
        "Photo2Id": ""
    }, {
        "ItemId": 131,
        "CategoryId": 9,
        "ItemType": "Food",
        "ItemOrder": 131,
        "Description": "About 1.5 lbs per day",
        "Url": "https://www.rei.com/search?q=backpacking+food",
        "IsWornClothing": false,
        "IsConsumable": true,
        "IsArchived": false,
        "Weight": 24,
        "Quantity": 4,
        "Notes": "Gotta have food, too!  I eat about 2,800 calories per day.  My typical day is granola with re-hydrated milk " +
            "for breakfast and then coffee.  Lunch is often cheese, tortillas, nuts and any fruit I might have.  Dinner is a " +
            "rehydrated meal, with candy and whiskey for dessert.  Snacks are protein bars, gorp, etc.",
        "Photo1Id": "6bcb2c48d763eb752bbe50f8caf4436c.jpg",
        "Photo2Id": ""
    }, {
        "ItemId": 132,
        "CategoryId": 9,
        "ItemType": "Fuel",
        "ItemOrder": 132,
        "Description": "JetBoil, single canister of 3.5 oz fuel",
        "Url": "https://www.jetboil.com/jetpower-fuel",
        "IsWornClothing": false,
        "IsConsumable": true,
        "IsArchived": false,
        "Weight": 6.75,
        "Quantity": 1,
        "Notes": "Fuel!",
        "Photo1Id": "1111202_primary.jpg",
        "Photo2Id": ""
    }, {
        "ItemId": 140,
        "CategoryId": 10,
        "ItemType": "Odor proof bag",
        "ItemOrder": 140,
        "Description": "OPSAK Odor-proof food bag",
        "Url": "https://www.rei.com/product/884265/loksak-opsak-odor-proof-barrier-bags-12-x-20-package-of-2",
        "IsWornClothing": false,
        "IsConsumable": false,
        "IsArchived": false,
        "Weight": 1,
        "Quantity": 1,
        "Notes": "Usually I just carry an odor proof bag, as it cuts down the weight versus the various other vaults, ursacks, etc.  See the " +
            "full set of gear reviews to see what Ursacks and vaults I use.",
        "Photo1Id": "5387b8cb-7aca-40ca-9a78-2b3aeda6d338.jpg",
        "Photo2Id": ""
    }, {
        "ItemId": 141,
        "CategoryId": 10,
        "ItemType": "Ursack bag",
        "ItemOrder": 141,
        "Description": "Ursack Minor",
        "Url": "https://ursack.com/collections/ursack-bags/products/ursack-minor",
        "IsWornClothing": false,
        "IsConsumable": false,
        "IsArchived": true,
        "Weight": 5.0,
        "Quantity": 1,
        "Notes": "I carried the Ursack Minor for awhile, my thinking being it would keep the rodents out of my food.  In the end, tho, I found " +
            "capacity somewhat limiting, plus the odor-proof bag seemed good enough to keep the rodent from trying to get thru my tent to the food.",
        "Photo1Id": "minor-open_1200x.jpg",
        "Photo2Id": ""
    }, {
        "ItemId": 142,
        "CategoryId": 10,
        "ItemType": "Ursack bag",
        "ItemOrder": 142,
        "Description": "Ursack Allmighty",
        "Url": "https://ursack.com/collections/ursack-bags/products/ursack-allmitey-white",
        "IsWornClothing": false,
        "IsConsumable": false,
        "IsArchived": true,
        "Weight": 13.0,
        "Quantity": 1,
        "Notes": "I carry this in areas where there are bears, but recognize this as an acceptable choice.  The Allmighty is tough, rodent and bear proof.",
        "Photo1Id": "MMI-AllMitey3_1200x.jpg",
        "Photo2Id": ""
    }, {
        "ItemId": 143,
        "CategoryId": 10,
        "ItemType": "Bear Vault",
        "ItemOrder": 143,
        "Description": "Bear Vault BV500",
        "Url": "https://bearvault.com/product/bv500/",
        "IsWornClothing": false,
        "IsConsumable": false,
        "IsArchived": true,
        "Weight": 41.0,
        "Quantity": 1,
        "Notes": "Ugh.  Take this only when you need to.  Heavy, hard-shell, but required.  I can fit about 8 days of food in here with careful smashing and packing.",
        "Photo1Id": "BV500.jpg",
        "Photo2Id": ""
    }, {
        "ItemId": 150,
        "CategoryId": 4,
        "ItemType": "Knee Support",
        "ItemOrder": 53,
        "Description": "Bracco Compression Knee Support",
        "Url": "https://www.amazon.com/gp/product/B005BINV84",
        "IsWornClothing": true,
        "IsConsumable": false,
        "IsArchived": false,
        "Weight": 6.8,
        "Quantity": 1,
        "Notes": "After my first section hike, well during it, my knees were *killing* me, especially on the downhills.  Well, I " +
            "thought, I'll just stretch out more next time.  Next section hike hurt too.  On this section I ran across a thru-hiker " +
            "and she said she had the same thing, but a pharmacist in Idyllwood suggested compression straps and they'd worked for " +
            "her ever since.  So I went down the same path and have never had a problem since.  By the way, if you are having the " +
            "same sort of issues, or are worried about it, I suggest reading and watching videos on IT-band management.  I learned " +
            "a lot and most of what I thought I knew was wrong (see section on what I learned).",
        "Photo1Id": "61jXL6G6O5L._AC_SL1000_.jpg",
        "Photo2Id": ""
    }, {
        "ItemId": 160,
        "CategoryId": 2,
        "ItemType": "Cooking Pot",
        "ItemOrder": 29,
        "Description": "TOAKS 650ml Titanium Cup",
        "Url": "https://www.toaksoutdoor.com/products/pot-650-l",
        "IsWornClothing": false,
        "IsConsumable": false,
        "IsArchived": false,
        "Weight": 2.7,
        "Quantity": 1,
        "Notes": "I love the Toaks pots.  I used the 450ml version for many years, but changed over to the 650ml because many of the " +
            "freeze-dried meals require 2 cups or a little more of water, so I needed the extra capacity.  Turns out the 'light' version " +
            "saves a .8 ozs, so win-win. It's rugged, holds my cookset, except the fuel, and does the job.",
        "Photo1Id": "light-titanium-650ml-pot-v2.jpg",
        "Photo2Id": ""
    }, {
        "ItemId": 170,
        "CategoryId": 8,
        "ItemType": "Ice Axe",
        "ItemOrder": 123,
        "Description": "CAMP Corsa",
        "Url": "https://www.rei.com/product/177135/camp-usa-corsa-ice-axe",
        "IsWornClothing": false,
        "IsConsumable": false,
        "IsArchived": false,
        "Weight": 7.1,
        "Quantity": 1,
        "Notes": "There is really no reason to not carry an ice axe.  And whatever you do, don't leave it on your pack, because " +
            "when you slip and fall, you won't have time to get it out.  I use the CAMP because it is light and does the job for " +
            "a non-serious snow travel.  If I was doing glaciers and stuff more regularly, I might choose differently, but for " +
            "the PCT, this does the job.",
        "Photo1Id": "CAMPIceAxe.jpg",
        "Photo2Id": ""
    }, {
        "ItemId": 171,
        "CategoryId": 8,
        "ItemType": "Microspikes",
        "ItemOrder": 124,
        "Description": "Kahtoola MICROspikes",
        "Url": "https://www.rei.com/product/890608/kahtoola-microspikes-traction-system",
        "IsWornClothing": false,
        "IsConsumable": false,
        "IsArchived": false,
        "Weight": 11.0,
        "Quantity": 1,
        "Notes": "Same reasoning here.  Carry crampons or micro-spikes.  You risk ending your thru-hike with an injury.  Is your " +
            "thru-hike worth 11 ounces for a few weeks?  Probably.  I love these spikes.",
        "Photo1Id": "Microspikes.jpg",
        "Photo2Id": ""
    }];



export const gearItemInfoActionCreators = {
    requestStaticGearItemInfo: () => async (dispatch, getState) => {

        if (getState().gearItemInfo.isLoading || getState().gearItemInfo.isLoaded) {
            // Don't keep adding more dispatches...
            return;
        }

        dispatch({ type: requestStaticGearItemInfoType });

        dispatch({ type: receiveStaticGearItemInfoType, gearItemInfo: staticGearItemDataArray });
    }
};

export const reducer = (state, action) => {
  state = state || initialState;

    if (action.type === requestStaticGearItemInfoType) {
    return {
        ...state,
        isLoading: true
    };
  }

    if (action.type === receiveStaticGearItemInfoType) {
    return {
      ...state,
        gearItemInfo: action.gearItemInfo,
        isLoading: false,
        isLoaded: true
    };
  }

  return state;
};
