import React, { Component } from 'react';
import { connect } from 'react-redux';

var myPackRootStyle = {
    color: '#eee',
    minHeight: '100vh',
    margin: '15px',
    fontSize: "x-large"
}


var iframeStyle = {
    minHeight: '90vh',
    minWidth: '95%'
}

class MyPack extends Component {

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render() {

        return (
            <div style={myPackRootStyle} >
                <div hidden>
                    <h1>Welcome to the section all about my pack.  Just below the various videos you will see all the gear in my current pack.  Enjoy, and I hope this section is helpful to you!</h1>
                </div>

                <p>Welcome to the section all about my pack.  Just below the various videos you will see all the gear in my
                    current pack.  Enjoy, and I hope this section is helpful to you!</p>
                <table>
                    <tbody>
                        <tr>
                            <td>
                                How I pack my pack:
                            </td>
                            <td style={{ margin:"15px" }} >
                                <video ref="packingMyPackVidRef"
                                width="560"
                                height="314"
                                margin="15px"
                                controls
                                preload="auto"
                                >
                                        <source src="https://seanandthepctstorage.blob.core.windows.net/nonblogmedia/PCTPack.mp4" type="video/mp4" />
                                </video>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                Setting up the tent:
                            </td>
                            <td style={{ margin: "15px" }} >
                                <video ref="settingUpTheTentVidRef"
                                    width="560"
                                    height="315"
                                    margin="15px"
                                    controls
                                    preload="auto"
                                >
                                    <source src="https://seanandthepctstorage.blob.core.windows.net/nonblogmedia/SettingUpTheTent.mp4" type="video/mp4" />
                                </video>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                Inside the tent:
                            </td>
                            <td style={{ margin: "15px" }} >
                                <video
                                    width="560"
                                    height="315"
                                    margin="15px"
                                    controls
                                    preload="auto"
                                >
                                    <source src="https://seanandthepctstorage.blob.core.windows.net/nonblogmedia/InsideTheTent.mp4" type="video/mp4" />
                                </video>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <hr />
                <p>If you would like to know why any particular piece of gear is here, or not here, look at the
                    Gear Reviews section.</p>
                <iframe title="My Current Pack" src={'https://lighterpack.com/r/lsilxw'} style={iframeStyle} />
            </div>
        );
    }
}


export default connect()(MyPack);