const requestStaticGearCategoryInfoType = 'REQUEST_GEAR_CATEGORY_INFO';
const receiveStaticGearCategoryInfoType = 'RECEIVE_GEAR_CATEGORY_INFO';
const initialState = { gearCategoryInfo: [], isLoading: false, isLoaded: false };

const staticGearCategoryDataArray = [
    {
        "CategoryId": 1,
        "CategoryName": "Pack, Tent and Sleep System",
        "CategoryOrder": 1
    }, {
        "CategoryId": 2,
        "CategoryName": "Food / Water System",
        "CategoryOrder": 2
    }, {
        "CategoryId": 3,
        "CategoryName": "Clothing Packed",
        "CategoryOrder": 3
    }, {
        "CategoryId": 4,
        "CategoryName": "Clothing Worn",
        "CategoryOrder": 4
    }, {
        "CategoryId": 5,
        "CategoryName": "Hygiene",
        "CategoryOrder": 5
    }, {
        "CategoryId": 7,
        "CategoryName": "Electronics",
        "CategoryOrder": 7
    }, {
        "CategoryId": 6,
        "CategoryName": "Medical",
        "CategoryOrder": 6
    }, {
        "CategoryId": 8,
        "CategoryName": "Miscellaneous",
        "CategoryOrder": 8
    }, {
        "CategoryId": 9,
        "CategoryName": "Consumables",
        "CategoryOrder": 9
    }, {
        "CategoryId": 10,
        "CategoryName": "Food Protection",
        "CategoryOrder": 10
    }];



export const gearCategoryInfoActionCreators = {
    requestStaticGearCategoryInfo: () => async (dispatch, getState) => {

        if (getState().gearCategoryInfo.isLoading || getState().gearCategoryInfo.isLoaded) {
            // Don't keep adding more dispatches...
            return;
        }

        dispatch({ type: requestStaticGearCategoryInfoType });

        dispatch({ type: receiveStaticGearCategoryInfoType, gearCategoryInfo: staticGearCategoryDataArray });
    }
};

export const reducer = (state, action) => {
  state = state || initialState;

    if (action.type === requestStaticGearCategoryInfoType) {
    return {
        ...state,
        isLoading: true
    };
  }

    if (action.type === receiveStaticGearCategoryInfoType) {
    return {
      ...state,
        gearCategoryInfo: action.gearCategoryInfo,
        isLoading: false,
        isLoaded: true
    };
  }

  return state;
};
