const requestBlogEntryType = 'REQUEST_BLOG_ENTRY';
const receiveBlogEntryType = 'RECEIVE_BLOG_ENTRY';
const initialState = { blogEntryList: [], isLoading: false };

import staticBlogEntryList from './BlogEntryDetails.json';

export const getBlogEntryActionCreators = {
    requestBlogEntry: (updateId) => async (dispatch, getState) => {
        if (getState().getBlogEntry.isLoading) {
            // Don't keep adding more dispatches...
            return;
        }

        // Check if we already have this one in the list
        var blogEntryList = getState().getBlogEntry.blogEntryList;

        if (blogEntryList.length > 0) {
            return;
        }

        dispatch({ type: requestBlogEntryType });

        console.log("Getting static blog entry list...");

        blogEntryList = staticBlogEntryList;

        dispatch({ type: receiveBlogEntryType, blogEntryList: blogEntryList });
    }
};

export const reducer = (state, action) => {
  state = state || initialState;

  if (action.type === requestBlogEntryType) {
    return {
        ...state,
        isLoading: true,
    };
  }

  if (action.type === receiveBlogEntryType) {
    return {
      ...state,
      blogEntryList: action.blogEntryList,
      isLoading: false
    };
  }

  return state;
};
