import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Col, Container, Row } from 'react-bootstrap';
import MyNavMenu from './MyNavMenu';

var styles = {
    grid: {
        backgroundColor: '#202020',
        paddingLeft: 0,
        paddingRight: 0
    },
    row: {
        marginLeft: 0,
        marginRight: 0
    },
    col: {
        paddingLeft: 0,
        paddingRight: 0
    }
};

class Layout extends Component {


    render() { 

        return (
            <Container fluid={true} style={styles.grid} >
                <Row nogutters="true">
                    <Col sm={2} style={{minWidth:"175px"}}>
                        <MyNavMenu />
                    </Col>
                    <Col sm={10}>
                        {this.props.children}
                    </Col>
                </Row>
            </Container>
        );
    }
}

export default connect()(Layout);